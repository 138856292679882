import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router";
import { ClientInfo } from "../api/biApi.types";
import usePermissions from "../auth/usePermissions";
import { ReportServiceContextProvider } from "../contexts/ReportServiceContext";
import { UserInfoActions, selectClientsWithAccess } from "../store/userSlice";
import { selectInitialLoadingCompleted } from "../store/workbookSlice";
import { PageNames } from "../types";
import Header from "./components/header/Header";
import useAccessIsForbidden from "./components/hooks/useAccessIsForbidden";
import useCellInEditMode from "./components/hooks/useCellInEditMode";
import useEntriliaReportsMonitoring from "./components/hooks/useEntriliaReportsMonitoring";
import useReportService from "./components/hooks/useReportService";
import { useActiveReport } from "./components/hooks/useWorksheet";
import InlineLoader from "./components/inlineLoader/InlineLoader";
import ReportsAccessibility from "./components/ReportsAccessibility";
import { getStoredSelectedCompanyCode, setStoredSelectedCompanyCode } from "./components/utils/storedClient";
import AccessNotPermittedMessage from "./errors/AccessNotPermittedMessage";
import useRefreshAllReports from "./components/hooks/useRefreshAllReports";

export default function App() {
  const dispatch = useDispatch();
  const initialLoadingCompleted = useSelector(selectInitialLoadingCompleted);
  const clientsWithAccess = useSelector(selectClientsWithAccess);
  const reportService = useReportService();
  const { refreshAllReports } = useRefreshAllReports(reportService.refreshReports);

  const {
    loadingUserData: loadingPermissions,
    userDataLoaded: permissionsLoaded,
    error: permissionError,
    unauthorizedError,
  } = usePermissions();
  const accessForbidden = useAccessIsForbidden();

  useCellInEditMode();
  useEntriliaReportsMonitoring();
  useActiveReport();

  useEffect(() => {
    const storedCompany = defineClientCode(clientsWithAccess);
    if (storedCompany) {
      setStoredSelectedCompanyCode(storedCompany);
      dispatch(UserInfoActions.setSelectedCompany(storedCompany));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsWithAccess]);

  if (!loadingPermissions && permissionError !== undefined) {
    return <Navigate to={PageNames.Error} />;
  }

  if (!loadingPermissions && unauthorizedError !== undefined) {
    return <AccessNotPermittedMessage />;
  }

  if (loadingPermissions || !permissionsLoaded) {
    return <InlineLoader text="Loading user information..." sx={{ bgcolor: "white" }} />;
  }

  if (permissionsLoaded && accessForbidden) {
    return <AccessNotPermittedMessage />;
  }

  return (
    <ReportServiceContextProvider {...reportService} refreshAllReports={refreshAllReports}>
      <Stack
        sx={{
          flex: 1,
          flexWrap: "nowrap",
          height: "100%",
          width: "100%",
        }}
      >
        <Header />
        {!initialLoadingCompleted && <InlineLoader text="Analyzing the workbook..." />}
        {initialLoadingCompleted && (
          <ReportsAccessibility>
            <Outlet />
          </ReportsAccessibility>
        )}
      </Stack>
    </ReportServiceContextProvider>
  );
}

const defineClientCode = (clients: ClientInfo[]) => {
  let storedCompany = getStoredSelectedCompanyCode();
  if (!storedCompany && clients.length === 1) {
    storedCompany = clients[0]?.clientCode ?? "";
    return storedCompany;
  }
  if (storedCompany && clients.find((c) => c.clientCode === storedCompany)) {
    return storedCompany;
  }
  return undefined;
};
