import { useEffect } from "react";
import { ExcelReportParameter, FilterModelType, ParameterDictionaryValue } from "../../api/biApi.types";
import { ReportParameter } from "../../store/parametersSlice";
import { XEntriliaReportParameter } from "../../store/store.types";
import { useParameterValues } from "../components/hooks/useParameterValues";
import FilterItemTableView from "./FilterItemTableView";

interface Props {
  storedParameter: ExcelReportParameter;
  filter: XEntriliaReportParameter | undefined;
  clientCode: string;
  onInitializeTableParameter: (parameter: ReportParameter) => void;
  onUpdateFilterItems: (values: ParameterDictionaryValue[], equalityType?: FilterModelType) => void;
}

export function FilterItemTable({
  storedParameter,
  filter,
  clientCode,
  onInitializeTableParameter,
  onUpdateFilterItems,
}: Props) {
  const currentParameter = useParameterValues(
    storedParameter.reportSource,
    storedParameter.name,
    storedParameter.id,
    clientCode
  );

  useEffect(() => {
    if (currentParameter.loaded && currentParameter?.value) {
      onInitializeTableParameter(currentParameter.value);
    }
  }, [currentParameter.value, currentParameter.loaded, onInitializeTableParameter]);

  return (
    <FilterItemTableView
      onApply={onUpdateFilterItems}
      filteredValues={filter?.availableDictionaryItems || []}
      storedParameter={storedParameter}
      selectedValues={filter?.values || []}
      isLoading={currentParameter.loading}
      equalityType={filter?.equalityType ?? FilterModelType.Equal}
      currentParameterValue={currentParameter.value}
    />
  );
}
