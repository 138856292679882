import { Grid2, LinearProgress, Typography } from "@mui/material";

export default function WorkbookReportItemDeletingContent() {
  return (
    <>
      <Grid2 sx={{ flex: 1, py: 0.625 }}>
        <LinearProgress color="error" sx={{ flex: 1, borderRadius: "3px" }} />
      </Grid2>
      <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.error.main })}>
        Deleting the report...
      </Typography>
    </>
  );
}
