import { Grid, Switch } from "@mui/material";
import React from "react";
import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import FieldLabel from "../components/common/FieldLabel";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (param: ExcelReportParameter, values: string[]) => void;
}
export function FilterItemBoolean({ info, filterValues, onUpdateFilter }: Props) {
  const [value, setValue] = React.useState<boolean | undefined>(() =>
    getBooleanFromValues(filterValues, info.defaultValues)
  );

  React.useEffect(() => {
    onUpdateFilter(info, [value?.toString() || "false"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onUpdateFilter]);

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid container sx={{ flex: 1 }}>
        <Switch size="small" checked={value} onChange={(_, value) => setValue(value)}></Switch>
      </Grid>
    </>
  );
}

function getBooleanFromValues(filterValues: ParameterDictionaryValue[], defaultValues?: ParameterDictionaryValue[]) {
  if (filterValues.length === 0) {
    if (defaultValues?.length === 1 && defaultValues[0]) {
      return isTrueBoolean(defaultValues[0]);
    }
    return undefined;
  }

  return isTrueBoolean(filterValues[0]);
}

function isTrueBoolean(value: string | undefined | null) {
  return value === "true" || value === "True" || value === "1";
}
