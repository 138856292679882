import { useMemo } from "react";
import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import { Autocomplete, Grid2, TextField } from "@mui/material";
import FieldLabel from "../components/common/FieldLabel";
import { getOptionFromValues } from "./utils/parametersHelper";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (values: string[]) => void;
}

export function FilterItemOption({ info, filterValues, onUpdateFilter }: Props) {
  const selectedItem = useMemo(() => {
    return info.options?.find((option) => option?.value === getOptionFromValues(filterValues, info.options)) || null;
  }, [info.options, filterValues]);

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        <Autocomplete
          size="small"
          sx={{ flex: 1 }}
          value={selectedItem}
          options={info.options || []}
          onChange={(_, value) => onUpdateFilter(value === null ? [] : [value.value])}
          renderInput={(params) => <TextField {...params} />}
          getOptionLabel={(option) => option?.text}
        />
      </Grid2>
    </>
  );
}
