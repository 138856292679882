import { ExcelReportParameter, FilterModelType, ParameterDictionaryValue } from "../../api/biApi.types";
import { XEntriliaReportParameter } from "../../store/store.types";
import { FilterItemBoolean } from "./FilterItemBoolean";
import { FilterItemDate } from "./FilterItemDate";
import { FilterItemDateRange } from "./FilterItemDateRange";
import { FilterItemOption } from "./FilterItemOption";
import { FilterItemTable } from "./FilterItemTable";
import { ReportParameter } from "../../store/parametersSlice";

interface FilterItemProps {
  info: ExcelReportParameter;
  filter: XEntriliaReportParameter | undefined;
  clientCode: string;
  onInitializeTableParameter: (parameters: ReportParameter) => void;
  onUpdateFilterItems: (values: ParameterDictionaryValue[], equalityType?: FilterModelType) => void;
}

const FilterItem = ({ info, filter, clientCode, onUpdateFilterItems, onInitializeTableParameter }: FilterItemProps) => {
  const filterValues = filter?.values || [];

  if (info.type === "date") {
    return <FilterItemDate info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilterItems} />;
  }
  if (info.type === "daterange") {
    return <FilterItemDateRange info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilterItems} />;
  }
  if (info.type === "boolean") {
    return <FilterItemBoolean info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilterItems} />;
  }
  if (info.type === "table") {
    return (
      <FilterItemTable
        storedParameter={info}
        filter={filter}
        clientCode={clientCode}
        onInitializeTableParameter={onInitializeTableParameter}
        onUpdateFilterItems={onUpdateFilterItems}
      />
    );
  }
  if (info.type === "option") {
    return <FilterItemOption info={info} filterValues={filterValues} onUpdateFilter={onUpdateFilterItems} />;
  }
  return <></>;
};

export default FilterItem;
