import { ClientInfo, UserPermissionSet } from "../../api/biApi.types";

export function getActualClients(permissions: UserPermissionSet[], clients: ClientInfo[]) {
  return permissions
    .map((permission) => {
      const client = clients.find((client) => client.clientCode === permission.clientCode);
      return client;
    })
    .filter((client): client is ClientInfo => client !== undefined);
  //TODO: not remove this code, it is for testing purposes
  // const client = clients.at(0);
  // if (client) {
  //   const newClients: ClientInfo[] = [];
  //   newClients.push(client);
  //   for (let index = 0; index < 100; index++) {
  //     newClients.push({
  //       ...client,
  //       clientCode: client.clientCode + index,
  //       title: client.title + index,
  //       branding: client.branding,
  //     });
  //   }
  //   return newClients;
  // }
  // return [];
}
