import { Grid2, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { ReportAccessState } from "../../api/biApi.types";
import { XEntriliaReportDeleting, XEntriliaReportDescriptor, XEntriliaReportPrecessing } from "../../store/store.types";
import WorkbookReportItemContentActionButton from "./WorkbookReportItemContentActionButton";
import WorkbookReportItemContentCaption from "./WorkbookReportItemContentCaption";
import WorkbookReportItemContentDetails from "./WorkbookReportItemContentDetails";
import WorkbookReportItemContentWarningText from "./WorkbookReportItemContentWarningText";

interface Props {
  report: XEntriliaReportDescriptor;
  precessingReport: XEntriliaReportPrecessing | undefined;
  deletingReport: XEntriliaReportDeleting | undefined;
  reportAccessState: ReportAccessState | undefined;
  isReadonly: boolean;
  isDeleted: boolean;
}

export default function WorkbookReportItemContent({
  report,
  isReadonly,
  isDeleted,
  precessingReport,
  deletingReport,
  reportAccessState,
}: PropsWithChildren<Props>) {
  return (
    <Grid2
      container
      flexDirection={"row"}
      flex={1}
      minWidth={0}
      sx={(t) => ({ color: isReadonly ? t.palette.text.disabled : t.palette.text.primary })}
      gap={1}
      width={"100%"}
    >
      <Stack flex={1} gap={0.25} minWidth={0}>
        <Stack flex={1} gap={1} flexWrap={"nowrap"} direction={"row"} alignItems={"center"}>
          <WorkbookReportItemContentCaption
            text={report.caption}
            color={deletingReport ? "text.disabled" : undefined}
          />
          <WorkbookReportItemContentWarningText isDeleted={isDeleted} isReadonly={isReadonly} />
        </Stack>
        <WorkbookReportItemContentDetails
          report={report}
          isReadonly={isReadonly}
          deletingReport={deletingReport}
          processingReport={precessingReport}
          reportAccessState={reportAccessState}
        />
      </Stack>
      <WorkbookReportItemContentActionButton
        report={report}
        processingReport={precessingReport}
        reportAccessState={reportAccessState}
      />
    </Grid2>
  );
}
