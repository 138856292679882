import {
  DataGridPremium,
  GridCellParams,
  GridColDef,
  GridGroupingColDefOverride,
  GridRowSelectionModel,
  isAutogeneratedRow,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useMemo, useState } from "react";
import { ParameterDictionary } from "../../../api/biApi.types";
import DataGridGroupHeader from "../../components/grid/DataGridGroupHeader";
import { ReportParameter } from "../../../store/parametersSlice";
import ScrollableFlexContainer from "../../components/common/ScrollableFlexContainer";
import InlineLoader from "../../components/inlineLoader/InlineLoader";

interface Props {
  isLoading: boolean;
  rows: ParameterDictionary[];
  onValueSelect: (selection: GridRowSelectionModel) => void;
  currentParameterValue: ReportParameter;
  selectedValueIds: number[];
  columns: GridColDef[];
}

const SelectParameterGrid = ({
  isLoading,
  rows,
  onValueSelect,
  selectedValueIds,
  currentParameterValue,
  columns,
}: Props) => {
  const apiRef = useGridApiRef();

  const [rowsSelection, setRowsSelection] = useState<GridRowSelectionModel>(selectedValueIds);

  const handleRowSelectionModelChange = useCallback(
    (newSelection: GridRowSelectionModel) => {
      setRowsSelection(newSelection);
      onValueSelect(newSelection);
    },
    [onValueSelect]
  );

  const groupColumnDef = useMemo((): GridGroupingColDefOverride | undefined => {
    return {
      hideDescendantCount: true,
      headerName: currentParameterValue.keyFieldName,
      sortable: true,
      aggregable: false,
      hideable: false,
      pinnable: false,
      filterable: false,
      flex: 1,
      leafField: currentParameterValue.keyFieldName,
      renderHeader: (params) => <DataGridGroupHeader params={params} />,
      colSpan: (_: GridCellParams, row) => {
        if (isAutogeneratedRow(row)) {
          return 2;
        }
        return 1;
      },
      groupingValueGetter: (params) => {
        return params ?? "Memo";
      },
    };
  }, [currentParameterValue.keyFieldName]);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      sorting: { sortModel: [{ field: currentParameterValue.keyFieldName, sort: "asc" }] },
      columns: currentParameterValue.groupByField && {
        columnVisibilityModel: { [currentParameterValue.keyFieldName]: false },
      },
      rowGrouping: currentParameterValue.groupByField && {
        model: [currentParameterValue.groupByField?.fieldName ?? ""],
      },
    },
  });

  return (
    <ScrollableFlexContainer>
      <DataGridPremium
        slots={{
          loadingOverlay: InlineLoader,
        }}
        checkboxSelection
        hideFooter
        rowHeight={24}
        columnHeaderHeight={30}
        apiRef={apiRef}
        loading={isLoading}
        rows={rows}
        columns={columns}
        rowSelectionModel={rowsSelection}
        groupingColDef={groupColumnDef}
        initialState={initialState}
        rowSelectionPropagation={{ descendants: true, parents: true }}
        disableColumnMenu
        isGroupExpandedByDefault={isGroupExpandedByDefault}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        sx={(t) => ({
          minHeight: "255px",
          "--DataGrid-rowBorderColor": t.palette.divider,
          border: "1px solid " + t.palette.divider,
          ".MuiDataGrid-columnHeaders": {
            borderBottom: "none",
          },
          ".MuiDataGrid-cell:focus-within": {
            outline: "unset",
          },
          ".MuiDataGrid-columnHeader:focus-within": {
            outline: "unset",
          },
          ".MuiDataGrid-row": {
            ".MuiDataGrid-cell": {
              borderTop: "none",
              ".MuiDataGrid-groupingCriteriaCell": {
                fontWeight: "500",
                ".MuiDataGrid-groupingCriteriaCellToggle": {
                  marginRight: "4px",
                },
              },
            },
          },
          ".MuiButtonBase-root.MuiCheckbox-root": {
            p: 0.75,
          },
          ".MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop": {
            border: "none",
          },
        })}
      />
    </ScrollableFlexContainer>
  );
};

export default SelectParameterGrid;

const isGroupExpandedByDefault = () => true;
