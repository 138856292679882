import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Box, Button, Divider, Grid2, Stack, Switch, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import {
  ExcelReport,
  ExcelReportParameter,
  FilterModelType,
  ParameterDictionaryValue,
  ReportSource,
} from "../../api/biApi.types";
import { ReportParameter } from "../../store/parametersSlice";
import { XEntriliaReportParameter } from "../../store/store.types";
import cloneDeep from "../../utils/cloneDeep";
import { BackButtonDefault } from "../components/common/BackButton";
import ScrollableFlexContainer from "../components/common/ScrollableFlexContainer";
import FilterItem from "./FilterItem";
import SelectedColumnsContainer from "./SelectedColumnsContainer";
import { allDataRangeParametersAreValid, allRequiredParametersSet } from "./utils/allRequiredParametersSet";
import { getParameterText, initializeFilters, prepareTableParameterItems } from "./utils/parametersHelper";

export interface SetParametersStepProps {
  report: ExcelReport;
  originalFilters: XEntriliaReportParameter[];
  submitButtonText: string;
  exportWithParameters: boolean;
  clientCode: string;
  submitButtonDisabled?: boolean;
  selectedColumns: number[];
  onBack: () => void;
  onContinue: (filters: XEntriliaReportParameter[]) => void;
  onChangeExportWithParameters: (withParameters: boolean) => void;
  onColumnsSelected: (columns: number[]) => void;
}

export default function SetParametersStep({
  report,
  originalFilters,
  submitButtonText,
  exportWithParameters,
  clientCode,
  submitButtonDisabled,
  selectedColumns,
  onBack,
  onContinue,
  onChangeExportWithParameters,
  onColumnsSelected,
}: SetParametersStepProps) {
  const [filters, setFilters] = useState<XEntriliaReportParameter[]>(
    initializeFilters(originalFilters, report.parameters)
  );

  const updateFilterValues = useCallback(
    (storedParameter: ExcelReportParameter, values: ParameterDictionaryValue[], equalityType?: FilterModelType) => {
      setFilters((prev) => {
        const copy = cloneDeep(prev);
        const existingItemIndex = copy.findIndex((p) => p.id === storedParameter.id);
        const existingItem = existingItemIndex >= 0 ? copy[existingItemIndex] : null;

        if (!existingItem) {
          return prev;
        }

        copy[existingItemIndex] = {
          ...existingItem,
          text: getParameterText(storedParameter, values),
          values: values,
          equalityType: equalityType,
        };

        return prepareTableParameterItems(copy);
      });
    },
    [setFilters]
  );

  const handleInitializeTableParameter = useCallback(
    (param: ReportParameter) => {
      const storedParameter = report.parameters.find((p) => p.id === param.id);

      if (!storedParameter) {
        return;
      }

      setFilters((prev) => {
        const copy = cloneDeep(prev);
        const existingItemIndex = copy.findIndex((p) => p.id === storedParameter.id);
        const existingItem = existingItemIndex >= 0 ? copy[existingItemIndex] : null;

        const payload: Pick<XEntriliaReportParameter, "allDictionaryItems" | "keyFieldName"> = {
          allDictionaryItems: param.values,
          keyFieldName: param.keyFieldName,
        };

        if (existingItem) {
          copy[existingItemIndex] = { ...existingItem, ...payload };
        } else {
          copy.push({
            id: storedParameter.id,
            name: storedParameter.name,
            values: [],
            text: getParameterText(storedParameter, []),
            equalityType: storedParameter.equalityType,
            description: storedParameter.description,
            reportSource: report.reportSource,
            type: storedParameter.type,
            subValuesParameterNo: storedParameter.subValuesParameterNo,
            ...payload,
          });
        }

        return prepareTableParameterItems(copy);
      });
    },
    [report.parameters, report.reportSource, setFilters]
  );

  const areRequiredParametersSet = useMemo(
    () => allRequiredParametersSet(report.parameters, filters),
    [report.parameters, filters]
  );

  const dateRangeParametersAreValid = useMemo(() => {
    return allDataRangeParametersAreValid(report.parameters, filters);
  }, [report.parameters, filters]);

  const groupedParametersByGroup = useMemo(() => groupBy(report.parameters, "group"), [report.parameters]);
  const canDisplayParameters = report.reportSource === ReportSource.BI && report.parameters.length > 0;

  const isSubmitButtonDisabled = !areRequiredParametersSet || !dateRangeParametersAreValid || submitButtonDisabled;

  return (
    <Stack sx={{ flex: 1, pt: 2, pb: 1, px: 2.5, gap: 1 }}>
      <Box>
        <BackButtonDefault onClick={onBack} />
      </Box>
      <Stack sx={{ gap: 2 }}>
        <Typography variant="h6" sx={{ wordBreak: "break-word" }}>
          {report.name}
        </Typography>
        <Divider sx={{ mt: 1.5 }} />
      </Stack>
      <ScrollableFlexContainer scrollContainerSx={{ pr: 0.5 }}>
        {groupedParametersByGroup &&
          Object.entries(groupedParametersByGroup).map(([key, parameters]) => (
            <Stack key={key} sx={{ pt: 1, gap: 2 }}>
              <Typography variant="subtitle1">{key}</Typography>
              <Stack
                sx={{
                  display: "grid",
                  gridTemplateColumns: "0.5fr 1fr",
                  alignItems: "center",
                  rowGap: 1,
                  columnGap: 1,
                }}
              >
                {parameters &&
                  parameters.map((p) => {
                    const currentFilterItem = filters.find((f) => f.id === p.id);
                    return (
                      <FilterItem
                        key={`${p.id}-${p.required}`}
                        info={p}
                        filter={currentFilterItem}
                        clientCode={clientCode}
                        onInitializeTableParameter={handleInitializeTableParameter}
                        onUpdateFilterItems={(params, eq) => updateFilterValues(p, params, eq)}
                      />
                    );
                  })}
              </Stack>
            </Stack>
          ))}
        {report.parameters.length > 0 && <Divider sx={{ borderStyle: "dashed", pt: 2 }} />}
        <SelectedColumnsContainer
          report={report}
          selectedColumns={selectedColumns}
          onColumnsSelected={onColumnsSelected}
        />
      </ScrollableFlexContainer>
      {canDisplayParameters && (
        <>
          <Divider />
          <Box sx={{ display: "flex", px: 1, alignItems: "center", justifyContent: "end" }}>
            <Typography variant="body2">Show Parameters</Typography>
            <Switch
              checked={exportWithParameters}
              size="small"
              onChange={(_, checked) => onChangeExportWithParameters(checked)}
            />
          </Box>
        </>
      )}

      <Divider />
      <Grid2 container sx={{ gap: 1, justifyContent: "end", px: 2 }}>
        <Button variant="text" color="secondary" size="small" onClick={onBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<EastRoundedIcon />}
          onClick={() => onContinue(filters)}
          disabled={isSubmitButtonDisabled}
        >
          {submitButtonText}
        </Button>
      </Grid2>
    </Stack>
  );
}

function groupBy<T>(arr: T[], property: keyof T): Record<string | number | symbol, T[]> {
  const groups: Record<string | number | symbol, T[]> = {};
  for (const item of arr) {
    const key = item[property];
    if (!((key as keyof T) in groups)) {
      groups[key as keyof T] = [];
    }
    groups[key as keyof T].push(item);
  }
  return groups;
}
