const ExcelApiStorageKey = "excelApiVersion";
const excelApiVersions = [
  "1.18",
  "1.17",
  "1.16",
  "1.15",
  "1.14",
  "1.13",
  "1.12",
  "1.11",
  "1.10",
  "1.9",
  "1.8",
  "1.7",
  "1.6",
  "1.5",
  "1.4",
  "1.3",
  "1.2",
  "1.1",
];
export const MinimumSupportVersion = "1.10";
export const RecommendedSupportVersion = "1.16";

export const getExcelApiVersion = (): [string | null, Error | undefined] => {
  if (Office.context.host !== Office.HostType.Excel) {
    return [null, new Error(`${Office.context.host} is not supported`)];
  }

  for (const version of excelApiVersions) {
    if (Office.context.requirements.isSetSupported("ExcelApi", version)) {
      return [version, undefined];
    }
  }

  return [null, new Error("Excel API version not supported")];
};

export const getCurrentExcelApiVersion = () => {
  let currVersion = getExcelApiVersionFromLocalStorage();
  if (!currVersion) {
    currVersion = getExcelApiVersion()[0];
    if (currVersion) {
      setExcelApiVersionToLocalStorage(currVersion);
    }
  }
  return currVersion;
};

export const isCurrentVersionLessThan = (targetVersion: string) => {
  const currVersion = getCurrentExcelApiVersion();
  if (!currVersion) {
    return true;
  }
  return isVersionLessThan(currVersion, targetVersion);
};

export const isVersionLessThan = (currentVersion: string, minimumRequiredVersion: string): boolean => {
  const [currentMajor = 0, currentMinor = 0] = currentVersion.split(".").map(Number);
  const [requiredMajor = 0, requiredMinor = 0] = minimumRequiredVersion.split(".").map(Number);

  if (currentMajor < requiredMajor) {
    return true;
  }
  if (currentMajor === requiredMajor && currentMinor < requiredMinor) {
    return true;
  }
  return false;
};

export const setExcelApiVersionToLocalStorage = (version: string | null) => {
  if (version === null) {
    localStorage.removeItem(ExcelApiStorageKey);
  } else {
    localStorage.setItem(ExcelApiStorageKey, version);
  }
};

const getExcelApiVersionFromLocalStorage = () => {
  return localStorage.getItem(ExcelApiStorageKey);
};
