import { ExcelReport, ReportKind } from "../../api/biApi.types";
import { Button, Dialog, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HorizontalFill from "../components/common/HorizontalFill";
import SelectColumnsPage from "./SelectColumnsPage";

interface Props {
  report: ExcelReport;
  selectedColumns: number[];
  onColumnsSelected: (columns: number[]) => void;
}

export default function SelectedColumnsContainer({ report, selectedColumns, onColumnsSelected }: Props) {
  const [showCustomize, setShowCustomize] = useState<boolean>(false);

  const selectedColumnNames = useMemo(
    () =>
      selectedColumns.map((id) => report.columns.find((c) => c.id === id)?.name).filter((name) => name !== undefined),
    [report.columns, selectedColumns]
  );

  if (report.reportKind === ReportKind.Report) {
    return null;
  }

  return (
    <>
      <Stack py={1} gap={0.5}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography variant="subtitle1">Columns</Typography>
          <HorizontalFill />
          <Button
            variant="outlined"
            size="small"
            startIcon={<SettingsOutlinedIcon />}
            color="secondary"
            onClick={() => setShowCustomize(true)}
          >
            Customize
          </Button>
        </Stack>
        {selectedColumnNames.map((name, index) => (
          <Typography key={`${name}_${index}`} variant="body1">
            {name}
          </Typography>
        ))}
      </Stack>
      {showCustomize && (
        <Dialog
          open={true}
          fullScreen
          onClose={(_, reason) => {
            if (reason !== "escapeKeyDown") {
              setShowCustomize(false);
            }
          }}
        >
          <SelectColumnsPage
            selectedColumnIds={selectedColumns}
            allColumns={report.columns}
            defaultColumns={report.selectedColumns}
            onColumnsSelected={onColumnsSelected}
            onBack={() => setShowCustomize(false)}
          />
        </Dialog>
      )}
    </>
  );
}
