import { Typography, Divider, Button, Switch, Stack, Box } from "@mui/material";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { cloneDeep } from "lodash";
import React, { useMemo, useState } from "react";
import {
  ParameterDictionary,
  ExcelReportParameter,
  FilterModelType,
  ParameterDictionaryValue,
  ReportSource,
} from "../../api/biApi.types";
import { BackButtonDefault } from "../components/common/BackButton";
import SearchField from "../components/common/SearchField";
import HorizontalFill from "../components/common/HorizontalFill";
import {
  getBooleanEquityType,
  getEquityType,
  getPrimaryDataValue,
  getDataRelatedColumnKeys,
} from "./utils/parametersHelper";
import { getColumn, NoValue } from "./selectParameterValueGridHelper";
import SelectParameterGrid from "./grid/SelectParameterGrid";
import { ReportParameter } from "../../store/parametersSlice";

interface Props {
  storedParameter: ExcelReportParameter;
  values: ParameterDictionary[];
  originalSelectedValues: ParameterDictionaryValue[];
  isLoading: boolean;
  onBack: () => void;
  onApply: (values: ParameterDictionaryValue[], equalityType: FilterModelType) => void;
  equalityType: FilterModelType;
  currentParameterValue: ReportParameter;
}

export default function SelectParameterValue({
  values,
  storedParameter,
  originalSelectedValues,
  onBack,
  onApply,
  isLoading,
  equalityType,
  currentParameterValue,
}: Props) {
  const [search, setSearch] = useState("");
  const [selectedValues, setSelectedValues] = useState<ParameterDictionaryValue[]>(cloneDeep(originalSelectedValues));
  const [equality, setEquality] = useState(equalityType);

  const dataColumnKeys = useMemo(() => getDataRelatedColumnKeys(values), [values]);

  const columns = React.useMemo((): GridColDef[] => {
    return dataColumnKeys.map((col) => getColumn(col, storedParameter.reportSource, currentParameterValue));
  }, [storedParameter.reportSource, currentParameterValue, dataColumnKeys]);

  const filteredValues = React.useMemo(() => {
    if (!search) {
      return values;
    }
    return values.filter((v) =>
      dataColumnKeys.some((col) => (v[col] || NoValue)?.toLowerCase().includes(search.toLowerCase()))
    );
  }, [values, search, dataColumnKeys]);

  const handleValueSelect = React.useCallback(
    (newSelectedValues: GridRowSelectionModel) => {
      setSelectedValues((prev) => [
        ...filteredValues
          .filter((fv) => newSelectedValues.includes(fv.id))
          .map((fv) => getPrimaryDataValue(fv, currentParameterValue?.keyFieldName)),
        ...prev.filter(
          (sv) => !filteredValues?.some((v) => getPrimaryDataValue(v, currentParameterValue?.keyFieldName) === sv)
        ),
      ]);
    },
    [setSelectedValues, filteredValues, currentParameterValue?.keyFieldName]
  );

  const selectedValueIds = React.useMemo(() => {
    return (
      values
        .filter((v) => selectedValues.includes(getPrimaryDataValue(v, currentParameterValue?.keyFieldName)))
        .map((v) => v.id) || []
    );
  }, [selectedValues, values, currentParameterValue?.keyFieldName]);

  return (
    <Stack sx={{ flex: 1, gap: 1 }}>
      <Stack sx={{ px: 2, py: 0.5, gap: 1 }}>
        <Box>
          <BackButtonDefault onClick={onBack} />
        </Box>
        <Typography variant="subtitle1" sx={{ wordBreak: "break-word" }}>
          {storedParameter.description}
        </Typography>
        <SearchField placeholder="Search" onSearch={setSearch} />
        {storedParameter.reportSource === ReportSource.BI && (
          <Box sx={{ pl: 0.5, alignItems: "center", display: "flex" }}>
            <Typography>Exclude values</Typography>
            <HorizontalFill />
            <Switch
              size="small"
              checked={getBooleanEquityType(equality)}
              onChange={(_, value) => setEquality(getEquityType(value))}
            />
          </Box>
        )}
      </Stack>
      <Stack flex={1} px={2}>
        <SelectParameterGrid
          columns={columns}
          rows={filteredValues}
          isLoading={isLoading}
          onValueSelect={handleValueSelect}
          selectedValueIds={selectedValueIds}
          currentParameterValue={currentParameterValue}
        />
      </Stack>
      <Divider />
      <Box sx={{ display: "flex", gap: 1, justifyContent: "end", px: 2, pb: 1 }}>
        <Button variant="text" color="secondary" size="small" onClick={onBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onApply(selectedValues, equality)}
          disabled={isLoading}
        >
          Apply
        </Button>
      </Box>
    </Stack>
  );
}
