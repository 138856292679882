import {
  Box,
  FormControl,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import ClientIcon from "../selectClient/ClientIcon";
import { getActualClients } from "../selectClient/getActualClients";
import { useMemo, useState } from "react";
import { ClientInfo, UserPermissionSet } from "../../api/biApi.types";
import SearchField from "../components/common/SearchField";

interface Props {
  defaultClientCode: string | undefined;
  permissions: UserPermissionSet[];
  clients: ClientInfo[];
  isLoading: boolean;
  onCompanyChange: (company: string) => void;
}

const CompanySelection = ({ onCompanyChange, defaultClientCode, clients, permissions, isLoading }: Props) => {
  const [clientCode, setClientCode] = useState<string>(() => defaultClientCode || "");
  const companies = useMemo(() => getActualClients(permissions, clients), [clients, permissions]);
  const [searchFilter, setSearchFilter] = useState("");
  const filteredCompanies = useMemo(
    () => companies.filter((c) => c.title.toLowerCase().includes(searchFilter.toLowerCase())),
    [companies, searchFilter]
  );
  const showSearch = companies.length > 10;

  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Company</Typography>
      <FormControl>
        <Select
          size="small"
          value={clientCode}
          autoFocus={defaultClientCode === undefined}
          onChange={(e) => e.target.value}
          displayEmpty
          renderValue={(code) => {
            const company = companies.find((c) => c.clientCode === code);
            if (company) {
              return (
                <Box display={"flex"} alignItems={"center"} gap={1} maxHeight={20}>
                  <ClientIcon logoUrl={company?.branding.logoMarkUrl} />
                  <Typography fontSize={13} noWrap>
                    {company?.title}
                  </Typography>
                </Box>
              );
            }

            return (
              <Typography fontSize={13} color="textSecondary">
                Select a company
              </Typography>
            );
          }}
          disabled={isLoading}
          MenuProps={{
            autoFocus: false,
            MenuListProps: {
              disablePadding: showSearch,
              sx: { maxHeight: 685 },
            },
          }}
        >
          <Box component="li">
            <Box component="ul">
              {showSearch && (
                <ListSubheader
                  onClick={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                  sx={{ p: 2, display: "flex" }}
                >
                  <SearchField onSearch={setSearchFilter} autoFocus />
                </ListSubheader>
              )}
              {filteredCompanies.map((c) => (
                <MenuItem
                  key={c.clientCode}
                  value={c.clientCode}
                  onClick={() => {
                    setClientCode(c.clientCode);
                    onCompanyChange(c.clientCode);
                  }}
                >
                  <ListItemIcon>
                    <ClientIcon logoUrl={c.branding.logoMarkUrl} />
                  </ListItemIcon>
                  <ListItemText slotProps={{ primary: { fontSize: 13, noWrap: true } }}>{c.title}</ListItemText>
                </MenuItem>
              ))}
            </Box>
          </Box>
        </Select>
      </FormControl>
    </Stack>
  );
};

export default CompanySelection;
