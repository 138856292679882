import TypographyTooltipEllipsis from "../components/common/TypographyTooltipEllipsis";

interface Props {
  text: string | undefined;
  color?: string;
}

const WorkbookReportItemContentCaption = ({ text, color }: Props) => {
  return (
    <TypographyTooltipEllipsis
      text={text ?? ""}
      typographyProps={{ variant: "subtitle2", lineHeight: "14.4px", fontSize: "12px", color }}
      tooltipProps={{
        disableInteractive: true,
      }}
    />
  );
};

export default WorkbookReportItemContentCaption;
