import { GridColDef } from "@mui/x-data-grid-premium";
import { ReportSource } from "../../api/biApi.types";
import { ReportParameter } from "../../store/parametersSlice";

export const NoValue = "No Value";

const getFallbackValue = (value: unknown, reportSource: ReportSource, isKeyFieldColumn: boolean) => {
  if (reportSource === ReportSource.BI && isKeyFieldColumn) {
    return NoValue;
  }

  return value;
};

export const getColumn = (field: string, reportSource: ReportSource, reportParameter: ReportParameter): GridColDef => {
  const column: GridColDef = {
    field: field,
    headerName: field,
    disableColumnMenu: true,
    flex: 1,
    disableReorder: true,
    valueFormatter: (value, _, col) =>
      value ?? getFallbackValue(value, reportSource, col.field === reportParameter.keyFieldName),
  };

  if (reportParameter.groupByField?.fieldName === field) {
    column.groupingValueGetter = (value) =>
      value ? (reportParameter.groupByField?.valueCaptions[value as string] ?? value) : "Memo";
  }

  return column;
};
