import { Grid2, Typography, Divider, Button, Switch } from "@mui/material";
import { DataGridPremium, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { cloneDeep } from "lodash";
import React from "react";
import {
  BcParameterDictionary,
  ExcelReportParameter,
  FilterModelType,
  ParameterDictionaryValue,
  ReportSource,
} from "../../api/biApi.types";
import { BackButtonDefault } from "../components/common/BackButton";
import SearchField from "../components/common/SearchField";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import HorizontalFill from "../components/common/HorizontalFill";
import { getParameterText, getParameterValue } from "./helpers/parametersHelper";
import ScrollableFlexContainer from "../components/common/ScrollableFlexContainer";
import { getBooleanEquityType, getEquityType, getHeaderValues } from "./utils/parametersHelper";
import { getColumn, NoValue } from "./selectParameterValueGridHelper";

interface Props {
  info: ExcelReportParameter;
  values: BcParameterDictionary[] | undefined;
  originalSelectedValues: ParameterDictionaryValue[];
  isLoading: boolean;
  onBack: () => void;
  onApply: (values: ParameterDictionaryValue[], equalityType: FilterModelType) => void;
  equalityType: FilterModelType;
}
export default function SelectParameterValue({
  values,
  info,
  originalSelectedValues,
  onBack,
  onApply,
  isLoading,
  equalityType,
}: Props) {
  const [search, setSearch] = React.useState("");
  const [selectedValues, setSelectedValues] = React.useState<ParameterDictionaryValue[]>(
    cloneDeep(originalSelectedValues)
  );

  const [equality, setEquality] = React.useState(equalityType);

  const mappedValues = React.useMemo(() => {
    return values?.map((v) => ({ id: v["id"] as number, value: getParameterValue(v), text: getParameterText(v) }));
  }, [values]);

  const columns = React.useMemo((): GridColDef[] => {
    const { valueHeaderName, textHeaderName } = getHeaderValues(values);
    const keyFieldName = "value";
    return [
      getColumn(keyFieldName, valueHeaderName, info.reportSource, keyFieldName),
      getColumn("text", textHeaderName, info.reportSource, keyFieldName),
    ];
  }, [values, info.reportSource]);

  const filteredValues = React.useMemo(() => {
    if (!search) {
      return mappedValues;
    }
    return (
      mappedValues?.filter((v) => (v.text || v.value || NoValue)?.toLowerCase().includes(search.toLowerCase())) || []
    );
  }, [mappedValues, search]);

  const handleValueSelect = React.useCallback(
    (newSelectedValues: GridRowSelectionModel) => {
      setSelectedValues((prev) => [
        ...(filteredValues?.filter((fv) => newSelectedValues.includes(fv.id)).map((fv) => fv.value) ?? []),
        ...prev.filter((sv) => !filteredValues?.some((v) => v.value === sv)),
      ]);
    },
    [setSelectedValues, filteredValues]
  );

  const rowSelectionModel = React.useMemo(() => {
    return mappedValues?.filter((v) => selectedValues.includes(v.value)).map((v) => v.id) || [];
  }, [selectedValues, mappedValues]);

  return (
    <Grid2 container sx={{ flex: 1, flexDirection: "column", gap: 1 }}>
      <Grid2 container sx={{ px: 2, py: 0.5, gap: 1, flexDirection: "column" }}>
        <Grid2>
          <BackButtonDefault onClick={onBack} />
        </Grid2>
        <Typography variant="subtitle2">{info.description}</Typography>
        <SearchField placeholder="Search..." onSearch={setSearch} />
        {info.reportSource === ReportSource.BI && (
          <Grid2 container sx={{ pl: 0.5, alignItems: "center" }}>
            <Typography variant="body2">Exclude values</Typography>
            <HorizontalFill />
            <Switch
              size="small"
              checked={getBooleanEquityType(equality)}
              onChange={(_, value) => {
                setEquality(getEquityType(value));
              }}
            />
          </Grid2>
        )}
      </Grid2>
      <Grid2 container flex={1} px={2}>
        <ScrollableFlexContainer>
          <DataGridPremium
            slots={{
              loadingOverlay: InlineLoader,
            }}
            loading={isLoading}
            columns={columns}
            rows={filteredValues}
            sx={(theme) => ({
              minHeight: "255px",
              border: "1px solid " + theme.palette.divider,
              cursor: "pointer",
              ".MuiDataGrid-columnHeaders": {
                borderBottom: "1px solid " + theme.palette.divider,
              },
              ".MuiDataGrid-cell:focus-within": {
                outline: "unset",
              },
              ".MuiDataGrid-columnHeader:focus-within": {
                outline: "unset",
              },
            })}
            rowHeight={24}
            checkboxSelection
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleValueSelect}
            hideFooter
            columnHeaderHeight={30}
          />
        </ScrollableFlexContainer>
      </Grid2>
      <Divider />
      <Grid2 container sx={{ gap: 1, justifyContent: "end", px: 2, pb: 1 }}>
        <Button variant="text" color="secondary" size="small" onClick={onBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onApply(selectedValues, equality)}
          disabled={isLoading}
        >
          Apply
        </Button>
      </Grid2>
    </Grid2>
  );
}
