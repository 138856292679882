import { Grid2, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { PageNames } from "../../../types";
import HorizontalFill from "../../components/common/HorizontalFill";
import ScrollableFlexContainer from "../../components/common/ScrollableFlexContainer";
import WorkbookReports from "../WorkbookReports";
import { ReportActions } from "./ReportActions";
import AlertsSection from "../AlertsSection";

const Reports = () => {
  const navigate = useNavigate();
  const navigateToAddReport = () => navigate(PageNames.Report);

  return (
    <>
      <Grid2 container sx={{ width: "100%", alignItems: "center", gap: 1 }}>
        <Typography variant="subtitle2">Reports</Typography>
        <HorizontalFill />
        <ReportActions onAddReport={navigateToAddReport} />
        <AlertsSection />
      </Grid2>
      <ScrollableFlexContainer>
        <WorkbookReports />
      </ScrollableFlexContainer>
    </>
  );
};

export default Reports;
