import { Stack, Typography } from "@mui/material";

interface Props {
  name: string;
  title: string;
}

const InfoBlockItem = ({ name, title }: Props) => {
  return (
    <Stack
      sx={{
        display: "grid",
        gridTemplateColumns: "5fr 7fr",
        gridTemplateRows: "1fr",
        gridColumnGap: 4,
      }}
    >
      <Typography variant="caption" color="secondary">
        {name}
      </Typography>
      <Typography variant="caption" sx={{ color: "text.primary" }}>
        {title}
      </Typography>
    </Stack>
  );
};

export default InfoBlockItem;
