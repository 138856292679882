import { logInformation } from "../../../logging";
import { XBorder, XCellBorders, XCellStyle, XFill, XFontStyle, XNumberFormat } from "../hooks/useExcelDataApi.types";
import { assignIfDifferent } from "./objectHelper";

export interface CustomFormatCheckResult {
  format: string;
  isFormatValid: boolean;
  shouldBeInsertedInLocal: boolean;
}

const applyFormat = async (
  cell: Excel.Range,
  format: string | undefined,
  checkedFormats: CustomFormatCheckResult[],
  context: Excel.RequestContext,
  shouldBeInsertedInLocal: boolean
) => {
  if (!format) {
    return undefined;
  }

  const checkResult = checkedFormats.find((f) => f.format === format);
  if (checkResult) {
    return checkResult.isFormatValid ? checkResult.format : undefined;
  }
  setNumberFormatSafe(cell, format, shouldBeInsertedInLocal);
  const { isCustomNumberFormatsCapacityLimitReached: isLimitReached } = await trySyncCustomNumberFormatAsync(context);
  if (isLimitReached) {
    logInformation("Custom number formats capacity limit reached", "applyFormat", { format: format });
  }
  checkedFormats.push({ format, isFormatValid: isLimitReached === false, shouldBeInsertedInLocal });

  return isLimitReached ? undefined : format;
};

export const getValidNumberFormatAsync = async (
  cell: Excel.Range,
  context: Excel.RequestContext,
  numberFormat: XNumberFormat | undefined,
  checkedFormats: CustomFormatCheckResult[]
) => {
  let shouldBeInsertedInLocal = false;
  let result = await applyFormat(cell, numberFormat?.customFormat, checkedFormats, context, shouldBeInsertedInLocal);

  if (result === undefined) {
    shouldBeInsertedInLocal = numberFormat?.defaultFormat !== "General";
    result = await applyFormat(cell, numberFormat?.defaultFormat, checkedFormats, context, shouldBeInsertedInLocal);
  }

  return result ?? "";
};

const trySyncCustomNumberFormatAsync = async (context: Excel.RequestContext) => {
  try {
    await context.sync();
    return { isCustomNumberFormatsCapacityLimitReached: false };
  } catch (error) {
    if (isCustomNumberFormatLimitReachedError(error)) {
      return { isCustomNumberFormatsCapacityLimitReached: true };
    }
    throw error;
  }
};

const isCustomNumberFormatLimitReachedError = (error: unknown) => {
  return (
    error instanceof OfficeExtension.Error &&
    error.code === Excel.ErrorCodes.invalidArgument &&
    (error.debugInfo.errorLocation === "Range.numberFormat" ||
      error.debugInfo.errorLocation === "Range.numberFormatLocal")
  );
};

export const setNumberFormatSafe = (cell: Excel.Range, format: string, shouldBeInsertedInLocal: boolean) => {
  if (shouldBeInsertedInLocal) {
    cell.numberFormatLocal = [[format]];
    return;
  }
  cell.numberFormat = [[format]];
};

export const setCommonFormatOptions = (
  format: Excel.CellPropertiesFormat,
  styles?: XCellStyle,
  defaultFormat?: Excel.RangeFormat
) => {
  if (!format || !styles?.alignment) {
    return;
  }

  const formatOptions: Partial<Excel.CellPropertiesFormat> = {};

  assignIfDifferent(formatOptions, "wrapText", styles.alignment.wrapText, defaultFormat?.wrapText ?? false);
  assignIfDifferent(
    formatOptions,
    "horizontalAlignment",
    styles.alignment.horizontal,
    defaultFormat?.horizontalAlignment ?? "General"
  );
  assignIfDifferent(
    formatOptions,
    "verticalAlignment",
    styles.alignment.vertical,
    defaultFormat?.verticalAlignment ?? "Bottom"
  );
  assignIfDifferent(formatOptions, "indentLevel", styles.alignment.indent, defaultFormat?.indentLevel ?? 0);
  if (Object.keys(formatOptions).length > 0) {
    Object.assign(format, formatOptions);
  }
};

export const setFill = (format?: Excel.CellPropertiesFormat, fill?: XFill, defaultFormat?: Excel.RangeFormat) => {
  if (!format || !fill) {
    return;
  }

  const fillObj: Excel.CellPropertiesFill = {};

  assignIfDifferent(fillObj, "pattern", fill.patternType);
  assignIfDifferent(fillObj, "color", fill.foregroundColor, defaultFormat?.fill?.color ?? "#FFFFFF");
  assignIfDifferent(fillObj, "patternColor", fill.backgroundColor);
  if (Object.keys(fillObj).length > 0) {
    format.fill = fillObj;
  }
};

export const setFont = (format?: Excel.CellPropertiesFormat, font?: XFontStyle, defaultFormat?: Excel.RangeFormat) => {
  if (!format || !font) {
    return;
  }

  const fontObj: Excel.CellPropertiesFont = {};

  assignIfDifferent(fontObj, "bold", font.bold, defaultFormat?.font?.bold ?? false);
  assignIfDifferent(fontObj, "color", font.color, defaultFormat?.font?.color ?? "#000000");
  assignIfDifferent(fontObj, "italic", font.italic, defaultFormat?.font?.italic ?? false);
  assignIfDifferent(fontObj, "name", font.name, defaultFormat?.font?.name);
  assignIfDifferent(fontObj, "size", font.size, defaultFormat?.font?.size);
  assignIfDifferent(fontObj, "strikethrough", font.strike, defaultFormat?.font?.strikethrough ?? false);
  assignIfDifferent(fontObj, "underline", font.underline, defaultFormat?.font?.underline ?? "None");
  if (Object.keys(fontObj).length > 0) {
    format.font = fontObj;
  }
};

export const setBorder = (format?: Excel.CellPropertiesFormat, borders?: XCellBorders) => {
  if (!format || !borders) {
    return;
  }

  const assignBorderSide = (side: keyof XCellBorders, borderData?: XBorder) => {
    if (!borderData) return;
    const { style, color, weight } = borderData;
    const newBorder: Partial<XBorder> = {};
    if (style !== undefined && style !== null) newBorder.style = style;
    if (color !== undefined && color !== null) newBorder.color = color;
    if (weight !== undefined && weight !== null) newBorder.weight = weight;

    if (Object.keys(newBorder).length > 0) {
      (format.borders ??= {})[side] = newBorder;
    }
  };

  assignBorderSide("bottom", borders.bottom);
  assignBorderSide("left", borders.left);
  assignBorderSide("right", borders.right);
  assignBorderSide("top", borders.top);
};
