import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import { Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React from "react";
import { formatDate } from "../../utils/Utilities";
import FieldLabel from "../components/common/FieldLabel";
import { getDateFromValues, getDateToValues } from "./utils/dateRangeHelper";
import BcDatePicker from "../components/common/bc-datepicker/BcDatePicker";
import { isDate } from "date-fns";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (param: ExcelReportParameter, values: string[]) => void;
}
export function FilterItemDateRange({ info, filterValues, onUpdateFilter }: Props) {
  const [fromValue, setFromValue] = React.useState<Date | null>(() =>
    getDateFromValues(filterValues, info.defaultValues)
  );
  const [toValue, setToValue] = React.useState<Date | null>(() => getDateToValues(filterValues, info.defaultValues));

  React.useEffect(() => {
    let fValue = "";
    if (fromValue && isDate(fromValue) && !isNaN(fromValue.getTime())) {
      fValue = formatDate(fromValue);
    }
    let tValue = "";
    if (toValue && isDate(toValue) && !isNaN(toValue.getTime())) {
      tValue = formatDate(toValue);
    }
    onUpdateFilter(info, [fValue, tValue]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromValue, toValue, onUpdateFilter]);

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container sx={{ flex: 1, gap: 1 }}>
          <BcDatePicker
            maxDate={toValue ?? undefined}
            onChange={(newValue) => {
              if (newValue && !isNaN(newValue.getTime())) {
                setFromValue(newValue);
                return;
              }
              if (newValue === null) {
                setFromValue(null);
              }
            }}
            value={fromValue}
            onError={(e) => {
              if (e === "invalidDate") {
                setFromValue(null);
              }
            }}
            required={info.required}
          />
          <BcDatePicker
            onChange={(newValue) => {
              if (newValue && !isNaN(newValue.getTime())) {
                setToValue(newValue);
              }
              if (newValue === null) {
                setToValue(null);
              }
            }}
            value={toValue}
            minDate={fromValue ?? undefined}
            onError={(e) => {
              if (e === "invalidDate") {
                setToValue(null);
              }
            }}
            required={info.required}
          />
        </Grid>
      </LocalizationProvider>
    </>
  );
}
