import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const ExpandAllIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <mask id="mask0_3656_40554" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3656_40554)">
        <path d="M9.41722 15.2503L7.25055 13.0837C6.98666 12.8198 6.92763 12.5177 7.07347 12.1774C7.2193 11.8371 7.47972 11.667 7.85472 11.667H12.1464C12.5214 11.667 12.7818 11.8371 12.9276 12.1774C13.0735 12.5177 13.0144 12.8198 12.7505 13.0837L10.5839 15.2503C10.5005 15.3337 10.4103 15.3962 10.313 15.4378C10.2158 15.4795 10.1117 15.5003 10.0005 15.5003C9.88944 15.5003 9.78527 15.4795 9.68805 15.4378C9.59083 15.3962 9.50055 15.3337 9.41722 15.2503Z" />
        <path d="M9.41722 4.41667L7.25055 6.58333C6.98666 6.84722 6.92763 7.14931 7.07347 7.48958C7.2193 7.82986 7.47972 8 7.85472 8H12.1464C12.5214 8 12.7818 7.82986 12.9276 7.48958C13.0735 7.14931 13.0144 6.84722 12.7505 6.58333L10.5839 4.41667C10.5005 4.33333 10.4103 4.27083 10.313 4.22917C10.2158 4.1875 10.1117 4.16667 10.0005 4.16667C9.88944 4.16667 9.78527 4.1875 9.68805 4.22917C9.59083 4.27083 9.50055 4.33333 9.41722 4.41667Z" />
      </g>
    </SvgIcon>
  );
};

export default ExpandAllIcon;
