import { XRow, XCell } from "../hooks/useExcelDataApi.types";

export const getChunkedRowRanges = (rows: XRow[], chunkSize: number) => {
  const ranges: {
    rangeReference: string;
    rows: XRow[];
  }[] = [];
  if (!rows || rows.length === 0) {
    return [];
  }
  let startIndex = 0;

  while (startIndex < rows.length) {
    const chunkedRows = rows.slice(startIndex, startIndex + chunkSize);
    startIndex += chunkSize;

    const firstRowCellRef = chunkedRows[0]?.cells[0]?.reference;
    if (firstRowCellRef === undefined) {
      break;
    }
    const lastRow = chunkedRows[chunkedRows.length - 1];
    const lastRowCellRef = lastRow?.cells[lastRow.cells.length - 1]?.reference;
    if (lastRowCellRef === undefined) {
      break;
    }

    ranges.push({
      rangeReference: `${firstRowCellRef}:${lastRowCellRef}`,
      rows: chunkedRows,
    });
  }

  return ranges;
};

export const fillMissingCells = (rows: XRow[], dimension: string): XRow[] => {
  const match = dimension.match(/^([A-Z]+)(\d+):([A-Z]+)(\d+)$/);
  if (!match || !match[1] || !match[2] || !match[3] || !match[4]) {
    return rows;
  }

  const startColLetters = match[1];
  const startRow = parseInt(match[2], 10);
  const endColLetters = match[3];
  const endRow = parseInt(match[4], 10);

  const startColIndex = columnLetterToNumber(startColLetters);
  const endColIndex = columnLetterToNumber(endColLetters);

  const cellMap = new Map<string, XCell>();
  for (const rowItem of rows) {
    for (const cell of rowItem.cells) {
      if (cell.reference) {
        cellMap.set(cell.reference, cell);
      }
    }
  }

  const filledRows: XRow[] = [];
  for (let rowIdx = startRow; rowIdx <= endRow; rowIdx++) {
    const row: XRow = { cells: [], bestFit: false };
    for (let colIdx = startColIndex; colIdx <= endColIndex; colIdx++) {
      const colLetters = numberToColumnLetter(colIdx);
      const cellRef = `${colLetters}${rowIdx}`;
      let cell = cellMap.get(cellRef);
      if (!cell) {
        // Fill missing cell
        cell = { reference: cellRef, dataType: "Empty", isFakeCell: true };
      }
      row.cells.push(cell);
    }
    filledRows.push(row);
  }
  return filledRows;
};

export const getMaxColumnFromDimension = (dimension: string): number => {
  const match = dimension.match(/^([A-Z]+)(\d+):([A-Z]+)(\d+)$/);
  if (!match || !match[3]) {
    return 0;
  }
  return columnLetterToNumber(match[3]);
};

/**
 * Converts Excel column letters to a 1-based column index.
 * A => 1, B => 2, ..., Z => 26, AA => 27, etc.
 */
export const columnLetterToNumber = (letters: string): number => {
  let total = 0;
  for (let i = 0; i < letters.length; i++) {
    total = total * 26 + (letters.charCodeAt(i) - 64);
  }
  return total;
};

/**
 * Converts a 1-based column index to Excel column letters.
 * 1 => A, 2 => B, ..., 26 => Z, 27 => AA, etc.
 */
export const numberToColumnLetter = (colIndex: number): string => {
  let letters = "";
  while (colIndex > 0) {
    const remainder = (colIndex - 1) % 26;
    letters = String.fromCharCode(65 + remainder) + letters;
    colIndex = Math.floor((colIndex - 1) / 26);
  }
  return letters;
};
