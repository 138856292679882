import { Stack, Typography } from "@mui/material";
import NoPermissionIcon from "../../icons/NoPermissionIcon";
import StyledPage from "../components/fullScreenLoader/StyledPage";
import LoginToAnotherAccountButton from "../components/common/LoginToAnotherAccountButton";

export default function AccessForbiddenPage() {
  return (
    <StyledPage
      containerProps={{
        alignItems: "center",
        justifyContent: "center",
        direction: "column",
        spacing: 1,
        gap: 1,
        px: 3,
      }}
    >
      {/*Has to be fixed in Task 4021: Apply an appropriate icon for access not granted page */}
      <NoPermissionIcon />
      <Stack sx={{ textAlign: "center", gap: 1, justifyContent: "center", pt: 1 }}>
        <Typography variant="h6">Your login is not authorized.</Typography>
        <Typography variant="body1">
          It seems that you have not been invited to access this service. Please reach out to a person with admin
          permissions in the system to request an invitation.
        </Typography>
      </Stack>
      <LoginToAnotherAccountButton />
    </StyledPage>
  );
}
