import { createSvgIcon } from "@mui/material";

export const UnsupportedExcelApiVersionIcon = createSvgIcon(
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="80" height="80" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.5 67.7007V56.0261H42.5V67.7007H37.5Z" fill="#C7DBE5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M55.0712 70.6252H24.8232V65.6252H55.0712V70.6252Z" fill="#C7DBE5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.71 21.3678H68.2906V59.5874H11.71V21.3678ZM16.71 26.3678V54.5874H63.2906V26.3678H16.71Z"
      fill="#C7DBE5"
    />
    <path
      d="M37.4343 12.6648C38.602 10.7379 41.3978 10.7379 42.5655 12.6648L56.5287 35.7051C57.7404 37.7044 56.3009 40.26 53.9631 40.26H26.0366C23.6988 40.26 22.2594 37.7044 23.471 35.7051L37.4343 12.6648Z"
      fill="#FF9800"
    />
    <path
      d="M42.2008 35.4229C42.856 34.2075 42.4019 32.6911 41.1865 32.036C39.9711 31.3808 38.4548 31.8349 37.7996 33.0503C37.1444 34.2656 37.5985 35.782 38.8139 36.4372C40.0293 37.0924 41.5456 36.6382 42.2008 35.4229Z"
      fill="white"
    />
    <path d="M42.7784 19.4237L41.6671 29.6502H38.4256L37.2217 19.4237H42.7784Z" fill="white" />
  </svg>,
  "UnsupportedExcelApiVersionIcon"
);

export default UnsupportedExcelApiVersionIcon;
