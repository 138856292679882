import { Button, Stack, Typography } from "@mui/material";
import UnsupportedExcelApiVersionIcon from "../../icons/UnsupportedExcelApiVersionIcon";
import StyledPage from "../components/fullScreenLoader/StyledPage";

const UnsupportedExcelApiVersionPage = () => {
  return (
    <StyledPage
      containerProps={{
        alignItems: "center",
        justifyContent: "center",
        direction: "column",
        spacing: 1,
        gap: 2,
        px: 3,
      }}
    >
      <UnsupportedExcelApiVersionIcon sx={{ width: 80, height: 80 }} />
      <Stack spacing={2} maxWidth={400}>
        <Stack sx={{ textAlign: "center", gap: 0.5, justifyContent: "center" }}>
          <Typography variant="subtitle1">Excel Version Not Supported</Typography>
          <Typography variant="body1" color="secondary">
            Your version of Excel does not meet the minimum requirements to use Entrilia for Excel. Please update to a
            supported version to access all features.
          </Typography>
        </Stack>
        <Button
          variant="outlined"
          color="primary"
          href="https://help.entrilia.com/en/articles/10671425-entrilia-for-excel-prerequisites"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Button>
      </Stack>
    </StyledPage>
  );
};

export default UnsupportedExcelApiVersionPage;
