import { Stack, Typography } from "@mui/material";
import NoAccessIcon from "../../icons/NoAccessIcon";
import LoginToAnotherAccountButton from "../components/common/LoginToAnotherAccountButton";
import StyledPage from "../components/fullScreenLoader/StyledPage";

export default function AccessNotPermittedMessage() {
  return (
    <StyledPage
      containerProps={{
        alignItems: "center",
        justifyContent: "center",
        direction: "column",
        spacing: 1,
        gap: 1,
        px: 3,
      }}
    >
      <NoAccessIcon />
      <Stack sx={{ textAlign: "center", gap: 1, justifyContent: "center", pt: 1 }}>
        <Typography variant="body1">
          Access to client information is not permitted. Please contact your system administrator for assistance in
          obtaining the necessary access.
        </Typography>
      </Stack>
      <LoginToAnotherAccountButton />
    </StyledPage>
  );
}
