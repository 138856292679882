/**
 * Utility function to conditionally assign a property only when:
 * - The value is not undefined or null
 * - The value is different from the default value (if provided)
 *
 * @param targetObj Object where property should be assigned
 * @param propName Property name to assign
 * @param value New value to assign
 * @param defaultValue Default value to compare against (if undefined, will still check if value should be assigned)
 * @param ignoreCase Whether to ignore case when comparing strings (default: true)
 * @returns Boolean indicating whether the assignment occurred
 */
export const assignIfDifferent = <T extends object, K extends keyof T>(
  targetObj: T,
  propName: K,
  value: T[K] | undefined | null,
  defaultValue?: T[K],
  ignoreCase = true
): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  if (defaultValue !== undefined && defaultValue !== null) {
    if (typeof value === "string" && typeof defaultValue === "string") {
      const sensitivity = ignoreCase ? "base" : "case";
      if (value.localeCompare(defaultValue, undefined, { sensitivity }) === 0) {
        return false;
      }
    } else if (value === defaultValue) {
      return false;
    }
  }

  targetObj[propName] = value;
  return true;
};
