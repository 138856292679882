import React from "react";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { gridExpandedSortedRowIdsSelector, gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid-premium";

export const setAllGroupsExpansion = (apiRef: React.MutableRefObject<GridApiPremium>, isExpanded: boolean) => {
  getAllRowIds(apiRef).forEach((rowId) => {
    if (apiRef.current.getRowNode(rowId)?.type === "group") {
      apiRef.current.setRowChildrenExpansion(rowId, isExpanded);
    }
  });
};

export const getAllRowIds = (apiRef: React.MutableRefObject<GridApiPremium>) =>
  gridFilteredSortedRowIdsSelector(apiRef);

export const isAnyGroupExpanded = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  return getVisibleRowIds(apiRef).some((id) => {
    const rowNode = apiRef.current.getRowNode(id);
    return rowNode?.type === "group" && rowNode.childrenExpanded;
  });
};

export const getVisibleRowIds = (apiRef: React.MutableRefObject<GridApiPremium>) =>
  gridExpandedSortedRowIdsSelector(apiRef);
