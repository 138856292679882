import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import { Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React from "react";
import { formatDate } from "../../utils/Utilities";
import FieldLabel from "../components/common/FieldLabel";
import { isDate } from "date-fns";
import BcDatePicker from "../components/common/bc-datepicker/BcDatePicker";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (param: ExcelReportParameter, values: string[]) => void;
}
export function FilterItemDate({ info, filterValues, onUpdateFilter }: Props) {
  const [value, setValue] = React.useState<Date | null>(() => getDateFromValues(filterValues, info.defaultValues));

  const onUpdateFilterRef = React.useRef(onUpdateFilter);
  onUpdateFilterRef.current = onUpdateFilter;

  React.useEffect(() => {
    if (value && isDate(value) && !isNaN(value.getTime())) {
      onUpdateFilter(info, [formatDate(value)]);
    } else {
      onUpdateFilter(info, []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdateFilter, value]);

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid container sx={{ flex: 1 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BcDatePicker
            onChange={(newValue) => {
              if (newValue && !isNaN(newValue.getTime())) {
                setValue(newValue);
                return;
              }
              if (newValue === null) {
                setValue(null);
              }
            }}
            onError={(e) => {
              if (e === "invalidDate") {
                setValue(null);
              }
            }}
            value={value}
            required={info.required}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}

function getDateFromValues(filterValues: ParameterDictionaryValue[], defaultValues?: ParameterDictionaryValue[]) {
  if (filterValues.length === 0) {
    if (defaultValues?.length === 1 && defaultValues[0]) {
      const date = new Date(defaultValues[0]);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    return null;
  }
  const date = new Date(filterValues[0] || "");
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}
