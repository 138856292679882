import React from "react";
import {
  BcParameterDictionary,
  ExcelReportParameter,
  FilterModelType,
  ParameterDictionaryValue,
} from "../../api/biApi.types";
import cloneDeep from "../../utils/cloneDeep";
import { useParameterValues } from "../components/hooks/useParameterValues";
import FilterItemTableView from "./FilterItemTableView";
import { getHeaderValues } from "./utils/parametersHelper";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  equalityType?: FilterModelType;
  clientCode: string;
  onUpdateFilter: (
    param: ExcelReportParameter,
    values: ParameterDictionaryValue[],
    equalityType?: FilterModelType
  ) => void;
}

export function FilterItemTable({ info, filterValues, onUpdateFilter, equalityType, clientCode }: Props) {
  const param = useParameterValues(info.reportSource, info.name, clientCode);
  const [selectedValues, setSelectedValues] = React.useState<ParameterDictionaryValue[]>([]);
  const [equality, setEquality] = React.useState(equalityType ?? info.equalityType);

  React.useEffect(() => {
    onUpdateFilter(info, selectedValues, equality);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues, onUpdateFilter, equality]);

  React.useEffect(() => {
    if (param.loaded) {
      setSelectedValues(
        filterUnavailableValues(getInitialValues(info.defaultValues || [], filterValues), param.value?.values || [])
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.value]);

  return (
    <FilterItemTableView
      onApply={(values, equality) => {
        setSelectedValues(values);
        setEquality(equality);
      }}
      filteredValues={param.value?.values}
      info={info}
      selectedValues={selectedValues}
      isLoading={param.loading}
      equalityType={equality}
    />
  );
}

function getInitialValues(defaultValues: ParameterDictionaryValue[], filterValues: ParameterDictionaryValue[]) {
  if (filterValues.length === 0 && defaultValues && defaultValues?.length > 0) {
    return cloneDeep(defaultValues);
  }
  return cloneDeep(filterValues);
}

function filterUnavailableValues(
  selectedValues: ParameterDictionaryValue[],
  availableParameters: BcParameterDictionary[]
): ParameterDictionaryValue[] {
  const { valueHeaderName } = getHeaderValues(availableParameters);

  return selectedValues.filter((v) => availableParameters.some((av) => av[valueHeaderName] === v));
}
