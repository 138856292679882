import { Alert, Collapse, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { ReportAccessState } from "../../../api/biApi.types";
import { XEntriliaReportDescriptor, XEntriliaReportParameter } from "../../../store/store.types";
import { selectClients } from "../../../store/userSlice";
import ReportAccessRestrictedAlert from "../../components/common/alerts/ReportAccessRestrictedAlert";
import ReportIsDeletedAlert from "../../components/common/alerts/ReportIsDeletedAlert";
import ConditionBlock from "./ConditionBlock";
import InfoBlock from "./info-block/InfoBlock";

interface Props {
  expanded: boolean;
  report: XEntriliaReportDescriptor;
  filters: XEntriliaReportParameter[];
  reportAccessState: ReportAccessState | undefined;
  isReadonly: boolean;
  isDeleted: boolean;
}

function FiltersPanel({ expanded, filters, report, isReadonly, isDeleted, reportAccessState }: Props) {
  const clients = useSelector(selectClients);
  const reportCompanyTitle = clients.find((client) => client.clientCode === report.clientCode)?.title ?? "";

  return (
    <Collapse in={expanded} sx={{ flexGrow: 1 }}>
      <Stack
        sx={(theme) => ({
          p: 1.5,
          borderTop: "1px solid " + theme.palette.divider,
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
          bgcolor: "#FAFAFA",
          width: "100%",
        })}
      >
        {isReadonly && <ReportAccessRestrictedAlert />}
        {!isReadonly && isDeleted && <ReportIsDeletedAlert />}
        {!isReadonly &&
          !isDeleted &&
          reportAccessState?.isConfigurationValid === false &&
          reportAccessState?.message && (
            <Alert severity="warning" sx={{ mb: 2, width: "100%" }}>
              {reportAccessState.message}
            </Alert>
          )}
        <InfoBlock report={report} companyTitle={reportCompanyTitle} isReadonly={isReadonly} />
        <ConditionBlock report={report} filters={filters} />
      </Stack>
    </Collapse>
  );
}

export default FiltersPanel;
