import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import { FilterModelType, ReportSource } from "../../../api/biApi.types";
import { orange } from "@mui/material/colors";
import { getUpdatedAtText } from "./conditionBlock.helper";
import { XEntriliaReportParameter, XEntriliaReportDescriptor } from "../../../store/store.types";
import TypographyTooltipEllipsis from "../../components/common/TypographyTooltipEllipsis";

interface ConditionBlockProps {
  filters: XEntriliaReportParameter[];
  report: XEntriliaReportDescriptor;
}

function ConditionBlock({ filters, report }: ConditionBlockProps) {
  const formatParamValues = React.useCallback((filter: XEntriliaReportParameter) => {
    return filter.text || filter.values.map((v) => v ?? "No Value").join(", ");
  }, []);

  return (
    <Grid2 container flexDirection={"column"} width={"100%"}>
      <Typography variant="subtitle2" sx={{ mt: 1, mb: 0.1 }}>
        Conditions
      </Typography>
      {filters.map((filter) => {
        const name = getFilterName(filter) + (filter.equalityType === FilterModelType.NotEqual ? "(<>)" : "");
        return (
          <Box
            key={filter.description + filter.name}
            sx={{
              display: "grid",
              gridTemplateColumns: "5fr 7fr",
              gridTemplateRows: "1fr",
              gridColumnGap: 4,
            }}
          >
            <TypographyTooltipEllipsis
              text={name}
              typographyProps={{
                variant: "caption",
                color: "secondary",
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: (theme) =>
                  filter.equalityType === FilterModelType.NotEqual ? orange[800] : theme.palette.text.primary,
              }}
            >
              {formatParamValues(filter)}
            </Typography>
          </Box>
        );
      })}
      <Grid2 sx={{ pt: 0.5 }}>
        <Typography variant="caption" color="secondary" sx={{ fontSize: 10 }}>
          {getUpdatedAtText(report.changedAt, report.changedBy)}
        </Typography>
      </Grid2>
    </Grid2>
  );
}

export default ConditionBlock;

function getFilterName(filter: XEntriliaReportParameter) {
  return (filter.reportSource === ReportSource.BI ? filter.description : filter.description || filter.name) ?? "";
}
