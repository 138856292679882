import { GridColumnHeaderParams, GridValidRowModel } from "@mui/x-data-grid-premium";
import { Stack, Typography } from "@mui/material";
import React from "react";
import ExpandCollapseAllButton from "../common/ExpandCollapseAllButton";

interface Props<T extends GridValidRowModel> {
  params: GridColumnHeaderParams<T, unknown, unknown>;
}

const DataGridGroupHeader = <T extends GridValidRowModel>({ params }: Props<T>) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <ExpandCollapseAllButton />
      <Typography variant="subtitle2">{params.colDef.headerName}</Typography>
    </Stack>
  );
};

export default DataGridGroupHeader;
