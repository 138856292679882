import { useGridApiContext } from "@mui/x-data-grid-premium";
import React from "react";
import { IconButton } from "@mui/material";
import CollapseAllIcon from "../../../icons/CollapseAllIcon";
import ExpandAllIcon from "../../../icons/ExpandAllIcon";
import { isAnyGroupExpanded, setAllGroupsExpansion } from "../../../utils/dataGridApiHelper";

const ExpandCollapseAllButton = () => {
  const apiRef = useGridApiContext();

  const anyExpanded = isAnyGroupExpanded(apiRef);

  const handleToggle: React.MouseEventHandler = (e) => {
    setAllGroupsExpansion(apiRef, !anyExpanded);
    e.stopPropagation();
  };

  return (
    <IconButton onClick={handleToggle} size="small">
      {anyExpanded && <CollapseAllIcon fontSize={"small"} />}
      {!anyExpanded && <ExpandAllIcon fontSize={"small"} />}
    </IconButton>
  );
};

export default ExpandCollapseAllButton;
