import { getCurrentExcelApiVersion } from "../../../utils/excelApiVersionHelper";
import { XRow } from "../hooks/useExcelDataApi.types";
import { CustomFormatCheckResult } from "./cellFormatHelper";
import { ExcelApiService } from "./excelApiService";
import { fillMissingCells, getChunkedRowRanges, getMaxColumnFromDimension } from "./loadRowsHelper";

export async function loadRows(
  context: Excel.RequestContext,
  sheet: Excel.Worksheet,
  rows: XRow[],
  dimension: string,
  checkedFormats: CustomFormatCheckResult[],
  defaultFormat: Excel.RangeFormat | undefined
) {
  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();

  let hasSynced = true;
  const filledRows = fillMissingCells(rows, dimension);
  const columnsNumber = getMaxColumnFromDimension(dimension);
  const rowsInOneChunk = Math.floor(2849 / columnsNumber);
  const excelApiService = new ExcelApiService(getCurrentExcelApiVersion(), checkedFormats, defaultFormat);
  const references = getChunkedRowRanges(filledRows, rowsInOneChunk);
  const ranges = sheet.getRanges(references.map((r) => r.rangeReference).join(";"));

  for (let idx = 0; idx < references.length; idx++) {
    const reference = references[idx];
    const range = ranges.areas.getItemAt(idx);
    if (!reference || !range) {
      continue;
    }
    hasSynced = false;

    for (let rowIndex = 0; rowIndex < reference.rows.length; rowIndex++) {
      const rowCells = reference.rows[rowIndex]?.cells;
      if (rowCells === undefined) {
        continue;
      }

      for (let cellIndex = 0; cellIndex < rowCells.length; cellIndex++) {
        const cell = rowCells[cellIndex];
        if (cell === undefined) {
          continue;
        }
        excelApiService.processCellData(cell);
      }
      excelApiService.moveToNextRow();
    }

    excelApiService.setAllValues(range);

    // eslint-disable-next-line office-addins/no-context-sync-in-loop
    await context.sync();
    context.application.suspendApiCalculationUntilNextSync();
    context.application.suspendScreenUpdatingUntilNextSync();
    hasSynced = true;
  }

  if (!hasSynced) {
    // eslint-disable-next-line office-addins/no-context-sync-in-loop
    await context.sync();
  }
}
