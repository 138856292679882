import { Grid2, OutlinedInput, InputAdornment, IconButton, Dialog } from "@mui/material";
import SelectParameterValue from "./SelectParameterValue";
import React from "react";
import {
  ParameterDictionary,
  ExcelReportParameter,
  FilterModelType,
  ParameterDictionaryValue,
} from "../../api/biApi.types";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Skeleton } from "@mui/material";
import FieldLabel from "../components/common/FieldLabel";
import { NoValue } from "./selectParameterValueGridHelper";
import { ReportParameter } from "../../store/parametersSlice";

interface Props {
  storedParameter: ExcelReportParameter;
  selectedValues: ParameterDictionaryValue[];
  filteredValues: ParameterDictionary[] | undefined;
  isLoading: boolean;
  onApply: (values: ParameterDictionaryValue[], equalityType: FilterModelType) => void;
  equalityType: FilterModelType;
  currentParameterValue: ReportParameter | undefined;
}

export default function FilterItemTableView({
  storedParameter,
  selectedValues,
  filteredValues,
  onApply,
  isLoading,
  equalityType,
  currentParameterValue,
}: Props) {
  const [showSelect, setShowSelect] = React.useState(false);
  const value = selectedValues.map((sv) => sv ?? NoValue).join(", ");

  return (
    <>
      <FieldLabel item={storedParameter} required={storedParameter.required} />
      <Grid2 container sx={{ flex: 1, position: "relative" }}>
        <OutlinedInput
          size="small"
          sx={{ flex: 1 }}
          disabled={isLoading}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setShowSelect(true)} disabled={isLoading}>
                <MoreHorizIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          }
        />
        {isLoading && (
          <Grid2 container sx={{ flex: 1, position: "absolute", pointerEvents: "none" }}>
            <Skeleton animation="wave" variant="rectangular" sx={{ width: "100%", height: 35, bgcolor: "#ffffff1c" }} />
          </Grid2>
        )}
      </Grid2>
      {showSelect && currentParameterValue && (
        <Dialog open={true} fullScreen onClose={() => setShowSelect(false)}>
          <SelectParameterValue
            values={filteredValues || []}
            storedParameter={storedParameter}
            originalSelectedValues={selectedValues}
            onBack={() => setShowSelect(false)}
            onApply={(values, equality) => {
              onApply(values, equality);
              setShowSelect(false);
            }}
            isLoading={isLoading}
            equalityType={equalityType}
            currentParameterValue={currentParameterValue}
          />
        </Dialog>
      )}
    </>
  );
}
