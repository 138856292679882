import { Box, Grid2, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  sx?: SxProps<Theme>;
  scrollContainerSx?: SxProps<Theme>;
}

const ScrollableFlexContainer = ({ sx, scrollContainerSx, children }: PropsWithChildren<Props>) => {
  return (
    <Grid2 container sx={{ width: "100%", flexDirection: "column", flex: 1, position: "relative", ...sx }}>
      <Box sx={{ position: "absolute", height: "100%", width: "100%", overflowY: "auto", ...scrollContainerSx }}>
        {children}
      </Box>
    </Grid2>
  );
};

export default ScrollableFlexContainer;
