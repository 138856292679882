import { Grid2, Typography, Button } from "@mui/material";
import ErrorIcon from "../../icons/ErrorIcon";
import StyledPage from "../components/fullScreenLoader/StyledPage";

interface Props {
  retryToLoadReports: () => void;
  goHome: () => void;
  errorMessage: string;
}
export default function LoadingReportsError({ retryToLoadReports, errorMessage, goHome }: Props) {
  return (
    <StyledPage
      containerProps={{
        alignItems: "center",
        justifyContent: "center",
        direction: "column",
        spacing: 1,
        gap: 2,
        px: 3,
      }}
    >
      <ErrorIcon />
      <Grid2 container sx={{ textAlign: "center", gap: 1, justifyContent: "center" }}>
        <Typography variant="h6">{errorMessage}</Typography>
      </Grid2>
      <Grid2 sx={{ display: "flex", gap: 1.5 }}>
        <Button variant="outlined" size="medium" onClick={retryToLoadReports} sx={{ minWidth: 102 }}>
          Try again
        </Button>
        <Button variant="contained" size="medium" onClick={goHome} sx={{ minWidth: 102 }}>
          Home page
        </Button>
      </Grid2>
    </StyledPage>
  );
}
