import { Button, Grid2, Stack, Typography } from "@mui/material";
import BigLogoIcon from "../../icons/BigLogoIcon";
import { PageNames } from "../../types";
import StyledPage from "../components/fullScreenLoader/StyledPage";

export default function SigninPage() {
  return (
    <StyledPage containerProps={{ alignItems: "center", justifyContent: "center", direction: "column" }}>
      <Stack
        sx={{
          flex: 1,
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          maxWidth: "320px",
        }}
      >
        <BigLogoIcon />
        <Typography variant="subtitle1" sx={{ pt: 1 }}>
          Welcome to Entrilia for Excel
        </Typography>
        <Typography variant="body2">Please authorize to create your first report</Typography>
        <Grid2 container sx={{ justifyContent: "center", pt: 3, width: "100%" }}>
          <Button href={PageNames.DoSignIn} variant="outlined" size="small" sx={{ flex: 1 }}>
            Sign in
          </Button>
        </Grid2>
      </Stack>
    </StyledPage>
  );
}
