import { logError, logInformation } from "../../../logging";
import { XWorksheetData } from "../hooks/useExcelDataApi.types";
import { columnsAutofit, loadColumnsWidth } from "./loadColumns";
import { loadGroupsAsync } from "./loadGroups";
import { loadImages } from "./loadImages";
import { loadNumberFormats } from "./loadNumberFormats";
import { loadRowsHeight } from "./loadRowsHeight";
import { loadRows } from "./loadRows";
import mergeCells from "./mergeCells";

export default async function populateWorksheet(
  context: Excel.RequestContext,
  worksheetData: XWorksheetData,
  worksheetId: string,
  onSyncCustomNumberFormatError: () => void
) {
  let eventBoolean = false;
  try {
    context.runtime.load("enableEvents");
    await context.sync();

    eventBoolean = context.runtime.enableEvents;
    context.runtime.enableEvents = !eventBoolean;

    const sheet = context.workbook.worksheets.getItem(worksheetId);
    context.application.suspendApiCalculationUntilNextSync();
    context.application.suspendScreenUpdatingUntilNextSync();

    await mergeCells(context, sheet, worksheetData.mergeCells);
    logInformation("Cells merged", "populateWorksheet");

    await loadColumnsWidth(context, sheet, worksheetData.columns);
    logInformation("Columns width loaded", "populateWorksheet");

    await loadRowsHeight(context, sheet, worksheetData.rows);
    logInformation("Rows height loaded", "populateWorksheet");

    await loadGroupsAsync(context, sheet, worksheetData.groups);
    logInformation("Groups loaded", "populateWorksheet");

    const firstCellRange = await getFirstCellRangeAsync(worksheetData.dimension.reference, sheet, context);
    logInformation("First cell range loaded", "populateWorksheet", {
      firstCellRange: firstCellRange?.address,
      dimension: worksheetData.dimension.reference,
    });

    const checkedFormats = await loadNumberFormats(context, worksheetData.rows, firstCellRange, () =>
      onSyncCustomNumberFormatError()
    );
    logInformation("Number formats loaded", "populateWorksheet");

    await loadRows(
      context,
      sheet,
      worksheetData.rows,
      worksheetData.dimension.reference,
      checkedFormats,
      firstCellRange?.format
    );
    logInformation("Rows loaded", "populateWorksheet");

    await columnsAutofit(context, sheet, worksheetData.columns);
    logInformation("Columns autofit", "populateWorksheet");

    await loadImages(context, sheet, worksheetData.images);
    logInformation("Images loaded", "populateWorksheet");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({ error });
    logError({ error }, "Error populating worksheet");
  } finally {
    context.runtime.enableEvents = eventBoolean;
  }
}

const getFirstCellRangeAsync = async (dimension: string, sheet: Excel.Worksheet, context: Excel.RequestContext) => {
  const firstCell = dimension.split(":")[0];
  if (!firstCell) {
    throw new Error(`Invalid dimension ${dimension}`);
  }
  const range = sheet.getRange(firstCell);
  // eslint-disable-next-line office-addins/no-navigational-load
  range.load(
    "address,format/font,format/wrapText,format/horizontalAlignment,format/verticalAlignment,format/indentLevel,format/fill"
  );
  await context.sync();

  return range;
};
