import { isDate } from "date-fns/isDate";
import {
  ParameterDictionary,
  FilterModelType,
  ParameterDictionaryValue,
  ExcelReportParameter,
  DimensionDictionary,
} from "../../../api/biApi.types";
import { XEntriliaReportParameter } from "../../../store/store.types";
import { formatDate } from "../../../utils/Utilities";
import { getDateRangeToValues, getDateFromValues, getDateToValues } from "./dateRangeHelper";
import { cloneDeep } from "lodash";

export const getDataRelatedColumnKeys = (values: ParameterDictionary[] | undefined) => {
  return Object.keys(values?.[0] || {}).filter((k) => k !== "id" && k !== "subValues");
};

export const getEquityType = (exclude: boolean) => {
  return exclude ? FilterModelType.NotEqual : FilterModelType.Equal;
};

export const getBooleanEquityType = (type: FilterModelType) => {
  return type.toString() === FilterModelType[FilterModelType.NotEqual];
};

export const getPrimaryDataValue = (
  param: ParameterDictionary,
  keyFieldName: string | undefined
): ParameterDictionaryValue => {
  return param[keyFieldName as keyof ParameterDictionary] as ParameterDictionaryValue;
};

export const getInitialValues = (parameters: ExcelReportParameter[]): XEntriliaReportParameter[] => {
  return parameters.map((p) => {
    const selectedOptions = [...(p.defaultValues || [])];

    if (p.type === "option" && selectedOptions.length === 0) {
      const firstOption = p.options?.length ? p.options[0]?.value : undefined;
      if (firstOption !== undefined) {
        selectedOptions.push(firstOption);
      }
    }
    return {
      name: p.name,
      id: p.id,
      values: selectedOptions,
      type: p.type,
      reportSource: p.reportSource,
      equalityType: p.equalityType,
      description: p.description,
      text: p.type === "option" ? p.options?.find((o) => o.value === p.defaultValues?.[0])?.text : undefined,
      keyFieldName: "",
    };
  });
};

export const prepareTableParameterItems = (parameters: XEntriliaReportParameter[]) => {
  const result = parameters.filter((p) => p.type !== "table");

  const topParents = parameters.filter(
    (p) =>
      p.type === "table" &&
      (p.subValuesParameterNo === undefined ||
        //The parameter's parent is not in the list
        parameters.find((pp) => pp.id === p.subValuesParameterNo) === undefined)
  );

  topParents.forEach((parent) => {
    const updatedParent = {
      ...parent,
      availableDictionaryItems: parent.allDictionaryItems, // Top parent item has no parent item, hence all items are available
    };
    if (isInitialized(updatedParent)) {
      updatedParent.values = updatedParent.values.filter((v) =>
        (updatedParent.allDictionaryItems || []).some(
          (item) => item[updatedParent.keyFieldName as keyof DimensionDictionary] === v
        )
      );
    }
    result.push(updatedParent);

    processChildParameters(updatedParent, parameters, result);
  });

  return result;
};

const processChildParameters = (
  parent: XEntriliaReportParameter,
  allParameters: XEntriliaReportParameter[],
  result: XEntriliaReportParameter[]
) => {
  const child = allParameters.find((p) => p.type === "table" && p.subValuesParameterNo === parent.id);

  if (!child) return;

  const parentSelectedValues = parent.values || [];
  let filteredAvailableDictionaryItems: ParameterDictionary[] | undefined;
  let filteredSelectedValues;

  if (isInitialized(parent) && isInitialized(child)) {
    if (parentSelectedValues.length === 0) {
      filteredAvailableDictionaryItems = (child.allDictionaryItems || []).filter((childItem) =>
        parent.availableDictionaryItems?.some((parentItem) =>
          childItem.subValues?.some(
            (subValue) => parentItem[parent.keyFieldName as keyof DimensionDictionary] === subValue
          )
        )
      );
    } else {
      filteredAvailableDictionaryItems = (child.allDictionaryItems || []).filter((childItem) =>
        parentSelectedValues.some((value) => childItem.subValues?.some((subValue) => value === subValue))
      );
    }

    filteredSelectedValues = (child.values || []).filter((selectedValue) =>
      filteredAvailableDictionaryItems?.some(
        (item) => item[child.keyFieldName as keyof DimensionDictionary] === selectedValue
      )
    );
  } else {
    filteredAvailableDictionaryItems = child.allDictionaryItems;
    filteredSelectedValues = child.values;
  }

  const updatedChild = {
    ...child,
    availableDictionaryItems: filteredAvailableDictionaryItems,
    values: filteredSelectedValues,
  };
  result.push(updatedChild);

  processChildParameters(updatedChild, allParameters, result);
};

const isInitialized = (parameter: XEntriliaReportParameter) => {
  return parameter.availableDictionaryItems !== undefined;
};

export const getBooleanFromValues = (
  filterValues: ParameterDictionaryValue[],
  defaultValues?: ParameterDictionaryValue[]
) => {
  if (filterValues.length === 0) {
    if (defaultValues?.length === 1 && defaultValues[0]) {
      return isTrueBoolean(defaultValues[0]);
    }
    return false;
  }

  return isTrueBoolean(filterValues[0]);
};

const isTrueBoolean = (value: string | undefined | null) => {
  return value === "true" || value === "True" || value === "1";
};

export const getOptionFromValues = (
  filterValues: ParameterDictionaryValue[],
  options: ExcelReportParameter["options"]
) => {
  if (filterValues.length === 0) {
    return options?.at(0)?.value || null;
  }

  return filterValues[0] || null;
};

export const getParameterText = (parameter: ExcelReportParameter, values: ParameterDictionaryValue[]) => {
  return parameter.type === "option" ? parameter.options?.find((o) => o.value === values[0])?.text : undefined;
};

export const initializeFilters = (originalFilters: XEntriliaReportParameter[], parameters: ExcelReportParameter[]) => {
  const copy = cloneDeep(originalFilters);
  return copy.map((of) => {
    const parameter = parameters.find((p) => p.id === of.id);
    if (!parameter) {
      return of;
    }

    of.id = parameter.id;
    of.name = parameter.name;
    of.text = getParameterText(parameter, []);
    of.equalityType = parameter.equalityType;
    of.description = parameter.description;
    of.reportSource = parameter.reportSource;
    of.type = parameter.type;
    of.subValuesParameterNo = parameter.subValuesParameterNo;

    if (of.type === "date") {
      const value = getDateFromValues(of.values, parameter.defaultValues);
      if (value && isDate(value) && !isNaN(value.getTime())) {
        of.values = [formatDate(value)];
      } else {
        of.values = [];
      }
      return of;
    }

    if (of.type === "daterange") {
      const fromValue = getDateFromValues(of.values, parameter.defaultValues);
      const toValue = getDateToValues(of.values, parameter.defaultValues);
      of.values = getDateRangeToValues(fromValue, toValue);
      return of;
    }

    if (of.type === "option") {
      of.text = getParameterText(parameter, of.values);
      return of;
    }

    if (of.type === "boolean") {
      of.values = [getBooleanFromValues(of.values, parameter.defaultValues)?.toString() || "false"];
      return of;
    }

    if (of.type === "table") {
      //Ignored. The parameter initialization happens after data is fetched
    }

    return of;
  });
};

export const buildRequestFilters = (filters: XEntriliaReportParameter[]): XEntriliaReportParameter[] => {
  return filters.map((f) => {
    return {
      description: f.description,
      id: f.id,
      name: f.name,
      values: f.values,
      equalityType: f.equalityType,
      reportSource: f.reportSource,
      type: f.type,
      text: f.text,
    };
  }) as XEntriliaReportParameter[];
};
