import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import { Grid2 } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useCallback } from "react";
import { formatDate } from "../../utils/Utilities";
import FieldLabel from "../components/common/FieldLabel";
import BcDatePicker from "../components/common/bc-datepicker/BcDatePicker";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (values: string[]) => void;
}
export function FilterItemDate({ info, filterValues, onUpdateFilter }: Props) {
  const handleValueChange = useCallback(
    (newValue: Date | null) => {
      let value = null;
      if (newValue && !isNaN(newValue.getTime())) {
        value = newValue;
        onUpdateFilter([formatDate(value)]);
      } else if (newValue === null) {
        value = null;
        onUpdateFilter([]);
      }
    },
    [onUpdateFilter]
  );

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BcDatePicker
            onChange={handleValueChange}
            onError={(e) => {
              if (e === "invalidDate") {
                handleValueChange(null);
              }
            }}
            value={getDateFromValues(filterValues, info.defaultValues)}
            required={info.required}
          />
        </LocalizationProvider>
      </Grid2>
    </>
  );
}

function getDateFromValues(filterValues: ParameterDictionaryValue[], defaultValues?: ParameterDictionaryValue[]) {
  if (filterValues.length === 0) {
    if (defaultValues?.length === 1 && defaultValues[0]) {
      const date = new Date(defaultValues[0]);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    return null;
  }
  const date = new Date(filterValues[0] || "");
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}
