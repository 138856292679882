import { Stack, Typography } from "@mui/material";
import StyledPage from "../components/fullScreenLoader/StyledPage";
import ErrorIcon from "../../icons/ErrorIcon";

export default function ErrorPage() {
  return (
    <StyledPage
      containerProps={{
        alignItems: "center",
        justifyContent: "center",
        direction: "column",
        spacing: 1,
        gap: 1,
        px: 3,
      }}
    >
      {/*Has to be fixed in Task 4026: Apply an appropriate icon for error page */}
      <ErrorIcon />
      <Stack sx={{ textAlign: "center", gap: 1, justifyContent: "center", pt: 1 }}>
        <Typography variant="h6">A technical error has occurred.</Typography>
        <Typography variant="body1">
          Please try again later. If the error persists, please contact an administrator for assistance.
        </Typography>
      </Stack>
    </StyledPage>
  );
}
