import { Grid2, Switch } from "@mui/material";
import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import FieldLabel from "../components/common/FieldLabel";
import { getBooleanFromValues } from "./utils/parametersHelper";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (values: string[]) => void;
}

export function FilterItemBoolean({ info, filterValues, onUpdateFilter }: Props) {
  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <Grid2 container sx={{ flex: 1 }}>
        <Switch
          size="small"
          checked={getBooleanFromValues(filterValues, info.defaultValues)}
          onChange={(_, value) => onUpdateFilter([value?.toString() || "false"])}
        />
      </Grid2>
    </>
  );
}
