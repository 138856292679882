import { getExcelApiVersion, setExcelApiVersionToLocalStorage } from "./excelApiVersionHelper";
import { logError } from "../logging";

export const initializeExcelApiVersion = () => {
  const [version, error] = getExcelApiVersion();
  if (error) {
    logError(error, "initializeExcelApiVersion");
    return;
  }
  setExcelApiVersionToLocalStorage(version);
};
