import { GridColDef } from "@mui/x-data-grid-premium";
import { ReportSource } from "../../api/biApi.types";

export const NoValue = "No Value";

export const getColumn = (
  field: string,
  headerName: string,
  reportSource: ReportSource,
  keyFieldName: string
): GridColDef => {
  return {
    field: field,
    headerName: headerName,
    disableColumnMenu: true,
    flex: 1,
    disableReorder: true,
    valueFormatter: (value, _, col) => value ?? getFallbackValue(value, reportSource, col.field === keyFieldName),
  };
};

const getFallbackValue = (value: unknown, reportSource: ReportSource, isKeyFieldColumn: boolean) => {
  if (reportSource === ReportSource.BI && isKeyFieldColumn) {
    return NoValue;
  }

  return value;
};
