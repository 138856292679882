import { XRow } from "../hooks/useExcelDataApi.types";
import { CustomFormatCheckResult, getValidNumberFormatAsync } from "./cellFormatHelper";

export async function loadNumberFormats(
  context: Excel.RequestContext,
  rows: XRow[],
  firstCellRange: Excel.Range | undefined,
  onSyncCustomNumberFormatError: () => void
) {
  if (!firstCellRange) {
    return [];
  }
  context.application.suspendApiCalculationUntilNextSync();
  context.application.suspendScreenUpdatingUntilNextSync();

  const checkedFormats: CustomFormatCheckResult[] = [];

  const cellsWithNumberFormat = rows.flatMap((f) => f.cells).filter((f) => f.format?.numberFormat !== undefined);

  for (let i = 0; i < cellsWithNumberFormat.length; i++) {
    const cell = cellsWithNumberFormat[i];
    if (!cell) {
      continue;
    }

    await getValidNumberFormatAsync(firstCellRange, context, cell.format?.numberFormat, checkedFormats);
  }

  if (checkedFormats.length > 0) {
    firstCellRange.numberFormatLocal = [[undefined]];
    firstCellRange.numberFormat = [[undefined]];
    if (checkedFormats.some((f) => !f.isFormatValid)) {
      onSyncCustomNumberFormatError();
    }
  }

  return checkedFormats;
}
