import { ExcelReportColumn } from "../../api/biApi.types";
import { Box, Button, Divider, Grid2, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { BackButtonDefault } from "../components/common/BackButton";
import ScrollableFlexContainer from "../components/common/ScrollableFlexContainer";
import HorizontalFill from "../components/common/HorizontalFill";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import SearchField from "../components/common/SearchField";
import SelectedColumnsDropContainer from "./SelectedColumnsDropContainer";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface Props {
  selectedColumnIds: number[];
  allColumns: ExcelReportColumn[];
  defaultColumns: number[];
  onColumnsSelected: (columns: number[]) => void;
  onBack: () => void;
}

export default function SelectColumnsPage({
  selectedColumnIds,
  allColumns,
  defaultColumns,
  onColumnsSelected,
  onBack,
}: Props) {
  const [columnIds, setColumnIds] = useState<number[]>(selectedColumnIds.map((c) => c));
  const [search, setSearch] = useState<string>("");

  const selectedColumns = useMemo(
    () =>
      columnIds
        .map((id) => allColumns.find((c) => c.id === id))
        .filter((column): column is ExcelReportColumn => column !== undefined),
    [allColumns, columnIds]
  );

  const hiddenColumns = useMemo(() => allColumns.filter((c) => !columnIds.includes(c.id)), [allColumns, columnIds]);
  const hiddenColumnsFiltered = useMemo(
    () =>
      hiddenColumns
        .filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
        .filter((column) => column !== undefined),
    [hiddenColumns, search]
  );
  const hiddenColumnsSubText = useMemo(
    () => `${hiddenColumns.length} column${hiddenColumns.length > 1 ? "s" : ""} hidden`,
    [hiddenColumns]
  );

  const applySelection = useCallback(() => {
    onColumnsSelected(columnIds);
    onBack();
  }, [columnIds, onBack, onColumnsSelected]);

  const removeColumn = useCallback((id: number) => {
    setColumnIds((prev) => prev.filter((col) => col !== id));
  }, []);

  const addColumn = useCallback((id: number) => {
    setColumnIds((prev) => [...prev, id]);
  }, []);

  const handleOrderChanged = useCallback(
    (columns: ExcelReportColumn[]) => {
      setColumnIds(columns.map((c) => c.id));
    },
    [setColumnIds]
  );

  const onApplyDefault = useCallback(() => {
    setColumnIds(defaultColumns);
  }, [defaultColumns]);

  return (
    <Stack sx={{ flex: 1, gap: 1 }}>
      <Stack sx={{ px: 2, py: 0.5, gap: 1 }}>
        <Box>
          <BackButtonDefault onClick={onBack} />
        </Box>
        <Stack direction={"row"} alignItems={"center"}>
          <Stack>
            <Typography variant="subtitle1">Columns Customization</Typography>
            <Typography sx={{ fontSize: 10 }} color={"secondary"}>
              {hiddenColumnsSubText}
            </Typography>
          </Stack>
          <HorizontalFill />
          <Button variant="outlined" color="secondary" size="small" onClick={onApplyDefault}>
            Reset to Defaults
          </Button>
        </Stack>
      </Stack>
      <Grid2 container flex={1}>
        <ScrollableFlexContainer scrollContainerSx={{ px: 2, py: 1 }}>
          <Stack sx={{ gap: 0.5 }}>
            <Stack sx={{ gap: 0.6 }}>
              {selectedColumns.length > 0 && (
                <Typography variant="caption" color="secondary" pb={0.5}>
                  Shown
                </Typography>
              )}

              <DndProvider backend={HTML5Backend}>
                <SelectedColumnsDropContainer
                  columns={selectedColumns}
                  onRemoveColumn={removeColumn}
                  onOrderChanged={handleOrderChanged}
                />
              </DndProvider>
              <Stack justifyContent={"center"} flex={1} pt={1.5} pb={0.5} spacing={0.5}>
                <Typography variant="caption" color="secondary">
                  Hidden
                </Typography>

                <Grid2 container flex={1.5}>
                  <SearchField placeholder="Search a column..." onSearch={setSearch} />
                </Grid2>
              </Stack>
              {hiddenColumnsFiltered.map((c) => {
                return (
                  <Grid2
                    key={c.id}
                    container
                    alignItems="center"
                    sx={{
                      gap: 1,
                      px: 1,
                      py: 0.5,
                      borderWidth: "1px",
                      borderStyle: "solid",
                      borderColor: "divider",
                      borderRadius: "2px",
                    }}
                  >
                    <Typography variant="body1">{c.name}</Typography>
                    <HorizontalFill />
                    <IconButton size="small" sx={{ color: "action.active" }} onClick={() => addColumn(c.id)}>
                      <VisibilityOffOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Grid2>
                );
              })}
            </Stack>
          </Stack>
        </ScrollableFlexContainer>
      </Grid2>
      <Divider />
      <Grid2 container sx={{ gap: 1, justifyContent: "end", px: 2, pb: 1 }}>
        <Button variant="text" color="secondary" size="small" onClick={onBack}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={selectedColumnIds.length === 0}
          onClick={applySelection}
        >
          Apply
        </Button>
      </Grid2>
    </Stack>
  );
}
