import { isVersionLessThan, RecommendedSupportVersion } from "../../../utils/excelApiVersionHelper";
import { XCell } from "../hooks/useExcelDataApi.types";
import { CustomFormatCheckResult, setBorder, setCommonFormatOptions, setFill, setFont } from "./cellFormatHelper";

interface CellStringValue {
  type: "String";
  basicValue: string;
}
interface CellDoubleValue {
  type: "Double";
  basicValue: number;
}
interface CellEmptyValue {
  type: "Empty";
  basicValue?: "";
}
export type CellValueAsJson = CellStringValue | CellDoubleValue | CellEmptyValue | undefined;
export type CellValueAsAny = XCell["value"];
export type CellValue = CellValueAsJson | CellValueAsAny;
export type CellFormula = XCell["formula"];

/* eslint-disable @typescript-eslint/no-explicit-any */

export class ExcelApiService {
  private getCellDataForValuesFunc: (cell: XCell) => any;
  private getCellDataForValuesAsJsonFunc: (cell: XCell) => CellValueAsJson;
  private defaultFormat: Excel.RangeFormat | undefined;
  private isOutdatedVersion: boolean;
  private checkedNumberFormats: CustomFormatCheckResult[];
  private rangeValues: any[][] = [];
  private rangeValuesAsJson: any[][] = [];
  private rangeStyles: Excel.SettableCellProperties[][] = [];
  private rangeFormulas: CellFormula[][] = [];
  private rangeNumberFormats: any[][] = [];
  private rangeNumberFormatsLocal: any[][] = [];

  private rowStyles: Excel.SettableCellProperties[] = [];
  private rowValues: any[] = [];
  private rowValuesAsJson: CellValueAsJson[] = [];
  private rowFormulas: CellFormula[] = [];
  private rowNumberFormats: any[] = [];
  private rowNumberFormatsLocal: any[] = [];

  constructor(
    version: string | null,
    checkedNumberFormats: CustomFormatCheckResult[],
    defaultFormat: Excel.RangeFormat | undefined
  ) {
    this.defaultFormat = defaultFormat;
    this.checkedNumberFormats = checkedNumberFormats;
    this.isOutdatedVersion = version === null || isVersionLessThan(version, RecommendedSupportVersion);
    if (this.isOutdatedVersion) {
      this.getCellDataForValuesFunc = this.getValueForOutdatedVersion;
      this.getCellDataForValuesAsJsonFunc = this.getValueAsJsonForOutdatedVersion;
    } else {
      this.getCellDataForValuesFunc = this.getValue;
      this.getCellDataForValuesAsJsonFunc = this.getValueAsJson;
    }
  }

  public processCellData(cell: XCell): void {
    this.rowValues.push(this.getCellDataForValuesFunc(cell));
    this.rowValuesAsJson.push(this.getCellDataForValuesAsJsonFunc(cell));
    this.rowStyles.push(this.getStyles(cell));
    this.rowFormulas.push(this.getFormula(cell));

    let numberFormat = this.checkedNumberFormats.find((f) => f.format === cell.format?.numberFormat?.customFormat);

    if (!numberFormat) {
      numberFormat = this.checkedNumberFormats.find((f) => f.format === cell.format?.numberFormat?.defaultFormat);
    }
    if (!numberFormat || !numberFormat.isFormatValid) {
      this.rowNumberFormats.push(undefined);
      this.rowNumberFormatsLocal.push(undefined);
    } else {
      this.rowNumberFormats.push(!numberFormat.shouldBeInsertedInLocal ? numberFormat.format : undefined);
      this.rowNumberFormatsLocal.push(numberFormat.shouldBeInsertedInLocal ? numberFormat.format : undefined);
    }
  }

  public moveToNextRow(): void {
    this.rangeStyles.push(this.rowStyles);
    this.rangeValues.push(this.rowValues);
    this.rangeValuesAsJson.push(this.rowValuesAsJson);
    this.rangeFormulas.push(this.rowFormulas);
    this.rangeNumberFormats.push(this.rowNumberFormats);
    this.rangeNumberFormatsLocal.push(this.rowNumberFormatsLocal);

    this.rowStyles = [];
    this.rowValues = [];
    this.rowValuesAsJson = [];
    this.rowFormulas = [];
    this.rowNumberFormats = [];
    this.rowNumberFormatsLocal = [];
  }

  public setAllValues(range: Excel.Range): void {
    this.setValues(range);
    range.setCellProperties(this.rangeStyles);
    range.formulas = this.rangeFormulas;
    range.numberFormatLocal = this.rangeNumberFormatsLocal;
    range.numberFormat = this.rangeNumberFormats;

    this.rangeStyles = [];
    this.rangeValues = [];
    this.rangeValuesAsJson = [];
    this.rangeFormulas = [];
    this.rangeNumberFormats = [];
    this.rangeNumberFormatsLocal = [];
  }

  public getFormula(cell: XCell): CellFormula {
    if (cell.isFakeCell) {
      return undefined;
    }
    return cell.formula ? `=${cell.formula}` : undefined;
  }

  private getStyles(cell: XCell): Excel.SettableCellProperties {
    if (cell.isFakeCell) {
      return {};
    }
    const format = {};
    setCommonFormatOptions(format, cell.format, this.defaultFormat);
    setBorder(format, cell?.format?.borders);
    setFont(format, cell?.format?.font, this.defaultFormat);
    setFill(format, cell?.format?.fill, this.defaultFormat);

    return { format };
  }

  private getValueForOutdatedVersion(cell: XCell) {
    if (cell.isFakeCell) {
      return undefined;
    }

    return cell.value;
  }

  private getValueAsJsonForOutdatedVersion(_: XCell): CellValueAsJson {
    return undefined;
  }

  private getValue(cell: XCell) {
    if (cell.isFakeCell) {
      return undefined;
    }

    if (cell.dataType === "Custom") {
      return cell.value;
    }

    if (cell.dataType === "Date") {
      return cell.value;
    }

    return undefined;
  }

  private getValueAsJson(cell: XCell): CellValueAsJson {
    if (cell.isFakeCell) {
      return undefined;
    }

    if (cell.dataType === "String") {
      //It is important not to set an empty string here, as in desktop Excel each cell with an empty string
      //tries to take a value from its left neighbor.
      if (!cell.value) {
        return undefined;
      }
      return {
        type: "String",
        basicValue: cell.value,
      };
    }

    if (cell.dataType === "Double") {
      return {
        type: "Double",
        basicValue: cell.value !== undefined && !isNaN(Number(cell.value)) ? Number(cell.value) : 0,
      };
    }

    return undefined;
  }

  private setValues(range: Excel.Range): void {
    range.values = this.rangeValues;
    if (!this.isOutdatedVersion) {
      // Uses the JSON-based API format which properly handles different data types
      // and prevents Excel from incorrectly converting or formatting values.
      // valuesAsJson is used when running on Excel versions that support new API.
      // see ADR-0001 for more details.
      range.valuesAsJson = this.rangeValuesAsJson;
    }
  }
}
