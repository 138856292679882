import { isDate } from "date-fns";
import { ParameterDictionaryValue } from "../../../api/biApi.types";
import { formatDate } from "../../../utils/Utilities";

export function getDateFromValues(
  filterValues: ParameterDictionaryValue[],
  defaultValues?: ParameterDictionaryValue[]
) {
  const value = filterValues[0] || defaultValues?.[0] || "";
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}

export function getDateToValues(filterValues: ParameterDictionaryValue[], defaultValues?: ParameterDictionaryValue[]) {
  const value = filterValues[1] || defaultValues?.[1] || "";
  const date = new Date(value);
  if (isNaN(date.getTime())) {
    return null;
  }
  return date;
}

export const getDateRangeToValues = (fromValue: Date | null, toValue: Date | null) => {
  let fValue = "";
  if (fromValue && isDate(fromValue) && !isNaN(fromValue.getTime())) {
    fValue = formatDate(fromValue);
  }
  let tValue = "";
  if (toValue && isDate(toValue) && !isNaN(toValue.getTime())) {
    tValue = formatDate(toValue);
  }

  return [fValue, tValue];
};
