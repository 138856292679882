import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ExcelReport } from "../../api/biApi.types";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import { deleteReportById, selectReportsProcessing, updateTrigger } from "../../store/reportsSlice";
import { XEntriliaReportParameter } from "../../store/store.types";
import {
  UserInfoActions,
  selectClientsWithAccess,
  selectSelectedCompany,
  selectUserPermissions,
} from "../../store/userSlice";
import { selectActiveWorksheetId } from "../../store/workbookSlice";
import BackButton from "../components/common/BackButton";
import useAvailableReports from "../components/hooks/useAvailableReports";
import ReportGeneration from "../components/ReportGeneration";
import { setStoredSelectedCompanyCode } from "../components/utils/storedClient";
import LoadingReportsError from "../errors/LoadingReportsError";
import { getParametersDescriptionForNewReport } from "../parameters/helpers/parametersHelper";
import SetParametersStepWrapper from "../parameters/SetParametersStepWrapper";
import AvailableReportsList from "./AvailableReportsList";
import CompanySelection from "./CompanySelection";
import NoAvailableCompanies from "./NoAvailableCompanies";
import { buildRequestFilters, getInitialValues } from "../parameters/utils/parametersHelper";

export default function AddReportPage() {
  const { addReport, refreshReport, cancelRequest } = useReportServiceContext();
  const navigate = useNavigate();
  const permissions = useSelector(selectUserPermissions);
  const clientsWithAccess = useSelector(selectClientsWithAccess);
  const activeWorksheetId = useSelector(selectActiveWorksheetId);
  const lastSelectedCompany = useSelector(selectSelectedCompany);
  const processingReports = useSelector(selectReportsProcessing);

  const { isLoading, loadingReportsError, retryToLoadReports, availableCompanyReports, availableReportsLoaded } =
    useAvailableReports({
      clientCode: lastSelectedCompany,
    });

  const dispatch = useDispatch();

  const [selectedReport, setSelectedReport] = useState<ExcelReport | undefined>();
  const [filters, setFilters] = useState<XEntriliaReportParameter[]>([]);
  const [withParameters, setWithParameters] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);
  const [generateReport, setGenerateReport] = useState(false);
  const [reportId, setReportId] = useState<string>();

  const processingReport = useMemo(() => {
    return processingReports?.find((lr) => lr.id === reportId);
  }, [reportId, processingReports]);

  const requestReportExport = useCallback(async () => {
    let newReportId;
    if (activeWorksheetId && selectedReport && lastSelectedCompany !== undefined) {
      newReportId = await addReport(
        activeWorksheetId,
        selectedReport,
        buildRequestFilters(filters),
        lastSelectedCompany,
        withParameters,
        selectedColumns,
        true
      );
    }
    setReportId(newReportId);
  }, [activeWorksheetId, selectedReport, lastSelectedCompany, addReport, filters, withParameters, selectedColumns]);

  const goHome = useCallback(() => {
    if (reportId !== undefined) {
      dispatch(deleteReportById(reportId));
    }
    dispatch(updateTrigger());
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const handleSelectCompany = (company: string) => {
    setStoredSelectedCompanyCode(company);
    dispatch(UserInfoActions.setSelectedCompany(company));
  };

  useEffect(() => {
    setSelectedColumns(selectedReport?.selectedColumns ?? []);
  }, [selectedReport]);

  const currentParameters = useMemo(
    () => getParametersDescriptionForNewReport(selectedReport?.parameters),
    [selectedReport?.parameters]
  );

  useEffect(() => {
    // SetParametersStep uses the parameters from this report.
    if (availableCompanyReports) {
      if (selectedReport) {
        const report = availableCompanyReports.find((r) => r.code === selectedReport.code);
        setSelectedReport(report);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableCompanyReports]);

  return (
    <>
      {selectedReport === undefined && (
        <Stack flex={1} spacing={1.5} py={2} px={2.5}>
          <Box>
            <BackButton pageNavigateTo={"/"} />
          </Box>
          <Stack spacing={2.5} height={"100%"}>
            {clientsWithAccess.length === 0 && <NoAvailableCompanies />}
            {clientsWithAccess.length > 0 && (
              <>
                <CompanySelection
                  onCompanyChange={handleSelectCompany}
                  clients={clientsWithAccess}
                  permissions={permissions}
                  defaultClientCode={lastSelectedCompany}
                  isLoading={isLoading}
                />
                {loadingReportsError === undefined && (
                  <Stack spacing={1} height={"100%"}>
                    <Typography variant="subtitle1">Reports</Typography>
                    {!lastSelectedCompany && (
                      <Typography variant="body1" color="text.secondary" fontSize={13}>
                        Please select a company
                      </Typography>
                    )}
                    {lastSelectedCompany && (
                      <AvailableReportsList
                        loading={isLoading}
                        availableReports={availableCompanyReports}
                        onReportSelected={(r) => {
                          setSelectedReport(r);
                          setFilters(getInitialValues(r.parameters));
                        }}
                        reportsLoaded={availableReportsLoaded}
                      />
                    )}
                  </Stack>
                )}
                {loadingReportsError !== undefined && (
                  <LoadingReportsError
                    goHome={goHome}
                    retryToLoadReports={() => {
                      if (lastSelectedCompany) {
                        retryToLoadReports(lastSelectedCompany);
                      }
                    }}
                    errorMessage={
                      "An error has occurred while loading the report. Please try again, or contact your system administrator if the error persists."
                    }
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>
      )}
      {selectedReport && !generateReport && activeWorksheetId && lastSelectedCompany && (
        <SetParametersStepWrapper
          parameters={currentParameters}
          report={selectedReport}
          originalFilters={filters}
          submitButtonText="Add report"
          exportWithParameters={withParameters}
          clientCode={lastSelectedCompany}
          submitButtonDisabled={processingReport !== undefined}
          selectedColumns={selectedColumns}
          onBack={() => setSelectedReport(undefined)}
          onContinue={(newFilters) => {
            setFilters(newFilters);
            setGenerateReport(true);
          }}
          onChangeExportWithParameters={setWithParameters}
          onColumnsSelected={setSelectedColumns}
          reportId={undefined}
        />
      )}
      {selectedReport && generateReport && activeWorksheetId && (
        <ReportGeneration
          handleReport={requestReportExport}
          refreshReport={refreshReport}
          goHome={goHome}
          cancelRequest={() => {
            setGenerateReport(false);
            cancelRequest(selectedReport.code, activeWorksheetId);
          }}
          reportId={reportId}
          processingReport={processingReport}
        />
      )}
    </>
  );
}
