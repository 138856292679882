import { XWorksheetData } from "../pages/components/hooks/useExcelDataApi.types";
import { CulturePatterns } from "./../pages/components/hooks/useCulturePatters";

export interface ExcelReport {
  code: string;
  name: string;
  reportSource: ReportSource;
  reportKind: ReportKind;
  level: string;
  levelOrder: number;
  order: number;
  reportType: string;
  parameters: ExcelReportParameter[];
  columns: ExcelReportColumn[];
  selectedColumns: number[];
}

export interface ExcelReportColumn {
  id: number;
  name: string;
}

export enum ReportSource {
  BC = "BC",
  BI = "BI",
}

export enum ReportKind {
  Report = "Report",
  MasterDataReport = "MasterDataReport",
}

export type ExcelReportParameterType = "date" | "daterange" | "table" | "boolean" | "option";

export interface ExcelReportParameter {
  id: string;
  name: string;
  type: ExcelReportParameterType;
  required: boolean;
  description: string;
  group: string;
  options?: ExcelParameterOption[];
  reportSource: ReportSource;
  defaultValues?: ParameterDictionaryValue[];
  equalityType: FilterModelType;
  subValuesParameterNo?: string;
}

export interface ExcelParameterOption {
  value: string;
  text: string;
}

export interface RequestReportResponse {
  reportExportCode: string;
}

export interface ExportedReportStatusResponse {
  status?: BcReportExportStatus;
}
export interface ExportedReportDataResponse {
  data?: XWorksheetData;
}

export interface BcReportExportStatus {
  completed: boolean;
  error: boolean;
  errorText?: string;
}

export type BcParameterDictionary = { [key: string]: string | number | null } & {
  subValues?: ParameterDictionaryValue[];
};

export type ParameterDictionaryValue = string | null;

export interface ParameterDictionaryResponse {
  dictionaryItems: {
    id: number;
    value: ParameterDictionaryValue;
    text: string;
    subValues?: string[];
  }[];
  fieldValueName: string;
  fieldTextName: string;
}

export interface ValidatedExcelReportParameter {
  parameter: ExcelReportParameter;
  userSelectedValues: ParameterDictionaryValue[];
}

export interface ValidateReportResponse {
  parameters: ValidatedExcelReportParameter[];
  validationErrorMessage?: string;
  hasAccessToReport?: boolean;
  isReportDeleted?: boolean;
  isConfigurationValid?: boolean;
  error?: string;
}

export interface ExcelReportParameterDescription {
  id: string;
  equalityType?: FilterModelType;
  selectedOptions: ParameterDictionaryValue[];
  type: ExcelReportParameterType;
}

export enum FilterModelType {
  Equal = "Equal",
  NotEqual = "NotEqual",
}

export interface UserPermissionsInfo {
  userId: string;
  userName: string;
  permissions: UserPermissionSet[];
}

export interface UserPermissionSet {
  clientId: string;
  clientTitle: string;
  clientCode: string;
  permissions: AccessPermission[];
}

export interface ClientInfo {
  title: string;
  clientCode: string;
  branding: ClientBranding;
}
export interface ClientBranding {
  logoTypeUrl?: string;
  logoMarkUrl?: string;
}
export interface ClientsResponse {
  clients: ClientInfo[];
}

export interface UserData extends UserPermissionsInfo {
  clients: ClientInfo[];
}

export type AccessPermission =
  | "ManageOrganizationClients"
  | "ViewOrganizationUsers"
  | "ManageOrganizationUsers"
  | "ViewMessages"
  | "ViewInvestorSettings"
  | "ManageInvestorSettings"
  | "ManageClientIntegrations"
  | "ViewClientIntegrations"
  | "ManageReports"
  | "ViewReports"
  | "ViewInvestorPortalOnBehalfOfInvestor"
  | "ViewInvestorPortalSettings"
  | "ManageInvestorPortalSettings"
  | "ViewDocumentCollections"
  | "ViewFundOps"
  | "ViewFundStructure"
  | "ManageFundStructure"
  | "UseAiAssistant"
  | "ManageOrganizationReports"
  | "ManageCompanySettings"
  | "ViewCompanySettings"
  | "ViewCommunityPortalSettings"
  | "ManageCommunityPortalSettings"
  | "ManageOrganizationReportTemplates"
  | "ViewExpenses"
  | "ManageExpenses"
  | "ViewPortfolioMonitoring"
  | "ManagePortfolioMonitoring"
  | "ViewAllCompanyFiles"
  | "ManageAllCompanyFiles"
  | "ViewInvestorRelationsFiles"
  | "ManageInvestorRelationsFiles"
  | "ViewExpenseManagementFiles"
  | "ManageExpenseManagementFiles"
  | "ViewPortfolioMonitoringFiles"
  | "ManagePortfolioMonitoringFiles"
  | "ViewInvestorRelationsNotes"
  | "ManageInvestorRelationsNotes"
  | "ViewExpenseManagementNotes"
  | "ManageExpenseManagementNotes"
  | "ViewPortfolioMonitoringNotes"
  | "ManagePortfolioMonitoringNotes"
  | "ViewInvestorRelationsDataImports"
  | "ManageInvestorRelationsDataImports"
  | "ViewPortfolioMonitoringDataImports"
  | "ManagePortfolioMonitoringDataImports"
  | "ViewExpenseManagementDataImports"
  | "ManageExpenseManagementDataImports"
  | "ViewInvestorRequests"
  | "ViewInvestorRequestsNotes"
  | "ManageInvestorRequestsNotes";

export interface GetReportsAccessStateRequest {
  clientCode: string;
  reportIds: string[];
}

export interface ReportAccessStateResponse {
  data: ReportAccessState[];
}

export interface ReportAccessState {
  storedReportId?: string;
  reportCode: string;
  hasAccessToReport?: boolean;
  isReportDeleted?: boolean;
  isConfigurationValid?: boolean;
  message?: string;
}

export interface ReportExportFileDataRequest {
  reportType: ReportSource | string | undefined;
  reportExportCode: string;
  culturePatterns: CulturePatterns | undefined;
}
