import {
  getCurrentExcelApiVersion,
  isCurrentVersionLessThan,
  RecommendedSupportVersion,
} from "../../utils/excelApiVersionHelper";
import { Grid2, Grid2Props, Link, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import TooltipWrapper from "../components/common/TooltipWrapper";

interface Props {
  sx?: Grid2Props["sx"];
}

const ExcelApiVersion = ({ sx }: Props) => {
  const version = getCurrentExcelApiVersion();
  const isCurrentVersionNotFullySupported = isCurrentVersionLessThan(RecommendedSupportVersion);

  return (
    <Grid2 flexDirection="row" display="flex" alignItems="center" gap={0.5} sx={sx}>
      <Typography sx={(theme) => ({ fontSize: "10px", color: theme.palette.text.secondary })}>
        Excel API {version}
      </Typography>
      {isCurrentVersionNotFullySupported && (
        <TooltipWrapper
          title={
            <Typography variant="caption">
              The Excel API version is outdated.{` `}
              <Link
                href="https://help.entrilia.com/en/articles/10671425-entrilia-for-excel-prerequisites"
                target="_blank"
                rel="noopener noreferrer"
                underline="none"
                sx={{ "&:hover": { textDecoration: "underline" } }}
              >
                Learn more.
              </Link>
            </Typography>
          }
        >
          <WarningAmberRoundedIcon fontSize="small" sx={{ fill: (t) => t.palette.warning.main }} />
        </TooltipWrapper>
      )}
    </Grid2>
  );
};

export default ExcelApiVersion;
