import { ExcelReportParameter, ParameterDictionaryValue } from "../../api/biApi.types";
import { Grid2 } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React, { useCallback } from "react";
import FieldLabel from "../components/common/FieldLabel";
import { getDateRangeToValues, getDateFromValues, getDateToValues } from "./utils/dateRangeHelper";
import BcDatePicker from "../components/common/bc-datepicker/BcDatePicker";

interface Props {
  info: ExcelReportParameter;
  filterValues: ParameterDictionaryValue[];
  onUpdateFilter: (values: string[]) => void;
}

export function FilterItemDateRange({ info, filterValues, onUpdateFilter }: Props) {
  const [fromValue, setFromValue] = React.useState<Date | null>(() =>
    getDateFromValues(filterValues, info.defaultValues)
  );
  const [toValue, setToValue] = React.useState<Date | null>(() => getDateToValues(filterValues, info.defaultValues));

  const handleFromValueChange = useCallback(
    (newValue: Date | null) => {
      let value = null;
      if (newValue && !isNaN(newValue.getTime())) {
        value = newValue;
      } else if (newValue === null) {
        value = null;
      }
      setFromValue(value);
      onUpdateFilter(getDateRangeToValues(value, toValue));
    },
    [setFromValue, toValue, onUpdateFilter]
  );

  const handleToValueChange = useCallback(
    (newValue: Date | null) => {
      let value = null;
      if (newValue && !isNaN(newValue.getTime())) {
        value = newValue;
      } else if (newValue === null) {
        value = null;
      }
      setToValue(value);
      onUpdateFilter(getDateRangeToValues(fromValue, value));
    },
    [setToValue, fromValue, onUpdateFilter]
  );

  return (
    <>
      <FieldLabel item={info} required={info.required} />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid2 container sx={{ flex: 1, gap: 1, width: "100%" }}>
          <BcDatePicker
            maxDate={toValue ?? undefined}
            onChange={handleFromValueChange}
            value={fromValue}
            onError={(e) => {
              if (e === "invalidDate") {
                handleToValueChange(null);
              }
            }}
            required={info.required}
          />
          <BcDatePicker
            onChange={handleToValueChange}
            value={toValue}
            minDate={fromValue ?? undefined}
            onError={(e) => {
              if (e === "invalidDate") {
                handleToValueChange(null);
              }
            }}
            required={info.required}
          />
        </Grid2>
      </LocalizationProvider>
    </>
  );
}
