import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withErrorHandling } from "../../../api/axiosHelper";
import { ApiError } from "../../../api/axiosHelper.types";
import { BiApi } from "../../../api/biApi";
import { ParameterDictionary, ReportSource } from "../../../api/biApi.types";
import { logError } from "../../../logging";
import { selectParameter, setParameter } from "../../../store/parametersSlice";
import { RootState } from "../../../store/store.types";

const getParameterDictionary = withErrorHandling((reportType: ReportSource, name: string, clientCode: string) =>
  BiApi.getParameterDictionary(reportType, name, clientCode)
);

export function useParameterValues(reportType: ReportSource, name: string, id: string, clientCode: string) {
  const dispatch = useDispatch();
  const storedValue = useSelector((state: RootState) => selectParameter(state, id, clientCode));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();

  useEffect(() => {
    if (storedValue?.values !== undefined) {
      return;
    }
    setLoading(true);

    getParameterDictionary(reportType, name, clientCode)
      .then(([data, error]) => {
        if (error && !data) {
          setError(error);
          logError(error, "useParameterValues");
        } else {
          dispatch(
            setParameter({
              id,
              name,
              clientCode,
              keyFieldName: data.keyFieldName,
              groupByField: data.groupByField,
              values: data.dictionaryItems.map(
                (item) =>
                  ({
                    ...item.items,
                    subValues: item.subValues,
                  }) as ParameterDictionary
              ),
            })
          );
        }
      })
      .finally(() => setLoading(false));
  }, [reportType, name, clientCode, storedValue, setLoading, dispatch, id]);

  return {
    value: storedValue,
    loaded: storedValue?.values !== undefined && !loading,
    loading: loading,
    error: error,
  };
}
