import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { ExcelReport } from "../../api/biApi.types";
import { useReportServiceContext } from "../../contexts/ReportServiceContext";
import {
  removeReportProcessing,
  selectProcessingReport,
  selectReports,
  updateReportParameters,
  updateTrigger,
} from "../../store/reportsSlice";
import { XEntriliaReportParameter } from "../../store/store.types";
import { selectActiveWorksheetId } from "../../store/workbookSlice";
import useAvailableReports from "../components/hooks/useAvailableReports";
import useExcelRunExecution from "../components/hooks/useExcelRunExecution";
import InlineLoader from "../components/inlineLoader/InlineLoader";
import ReportGeneration from "../components/ReportGeneration";
import { getStoredReportSettings } from "../components/utils/storedReportSettings";
import { getParametersDescriptionForExistingReport } from "../parameters/helpers/parametersHelper";
import SetParametersStepWrapper from "../parameters/SetParametersStepWrapper";
import { buildRequestFilters } from "../parameters/utils/parametersHelper";

export default function EditReportPage() {
  const dispatch = useDispatch();
  const { reportId } = useParams();
  const activeWorksheetId = useSelector(selectActiveWorksheetId);
  const storedReports = useSelector(selectReports);
  const processingReport = useSelector((s) => selectProcessingReport(s, reportId));
  const { excelRun } = useExcelRunExecution();

  const navigate = useNavigate();
  const { reloadReport, cancelRequest } = useReportServiceContext();

  const [filters, setFilters] = useState<XEntriliaReportParameter[]>([]);
  const [generateReport, setGenerateReport] = useState(false);
  const [reportToEdit, setReportToEdit] = useState<ExcelReport | undefined>();
  const [withParameters, setWithParameters] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);

  const storedReport = useMemo(() => storedReports.find((rf) => rf.id === reportId), [storedReports, reportId]);
  const { isLoading, availableCompanyReports } = useAvailableReports({
    clientCode: storedReport?.clientCode,
  });

  const restoreReportParameters = async () => {
    await excelRun(async (context) => {
      const currentReportSettings = (await getStoredReportSettings(context)).find((r) => r.id === reportId);
      if (reportId && currentReportSettings) {
        // If the report parameters were updated after validation, we update them in the redux store.
        // If a user goes back to the previous step, we need to revert the parameters to the stored ones.
        dispatch(updateReportParameters({ storedReportId: reportId, parameters: currentReportSettings.filters }));
      }
    });
  };

  const requestReportExport = useCallback(async () => {
    if (reportId && storedReport) {
      await reloadReport(storedReport, buildRequestFilters(filters), withParameters, selectedColumns, undefined, true);
    }
  }, [reportId, storedReport, reloadReport, filters, withParameters, selectedColumns]);

  const retryToLoadReport = useCallback(async () => {
    await requestReportExport();
  }, [requestReportExport]);

  const goHome = async () => {
    if (reportId !== undefined) {
      dispatch(removeReportProcessing(reportId));
    }
    await restoreReportParameters();
    dispatch(updateTrigger());
    navigate("/");
  };

  const goBack = async () => {
    await restoreReportParameters();
    navigate("/");
  };

  const currentParameters = useMemo(
    () => getParametersDescriptionForExistingReport(storedReport?.filters),
    [storedReport?.filters]
  );

  useEffect(() => {
    if (availableCompanyReports) {
      setReportToEdit(availableCompanyReports.find((ar) => ar.code === storedReport?.reportCode));
      setFilters(storedReport?.filters || []);
      setWithParameters(storedReport?.withParameters || false);
      setSelectedColumns(storedReport?.selectedColumns || []);
    }
  }, [
    availableCompanyReports,
    storedReport?.filters,
    storedReport?.withParameters,
    storedReport?.selectedColumns,
    storedReport?.reportCode,
  ]);

  if (isLoading) {
    return <InlineLoader text="Preparing report for editing..." />;
  }

  if (!reportToEdit || !activeWorksheetId || !storedReport?.clientCode) {
    return null;
  }

  return (
    <>
      {generateReport ? (
        <ReportGeneration
          handleReport={requestReportExport}
          refreshReport={retryToLoadReport}
          goHome={goHome}
          cancelRequest={() => {
            setGenerateReport(false);
            cancelRequest(reportToEdit.code, storedReport.worksheetId);
          }}
          reportId={reportId}
          processingReport={processingReport}
        />
      ) : (
        <SetParametersStepWrapper
          parameters={currentParameters}
          clientCode={storedReport?.clientCode}
          report={reportToEdit}
          originalFilters={filters}
          submitButtonText="Update report"
          exportWithParameters={withParameters}
          submitButtonDisabled={processingReport !== undefined}
          selectedColumns={selectedColumns}
          onChangeExportWithParameters={setWithParameters}
          onColumnsSelected={setSelectedColumns}
          onBack={goBack}
          onContinue={(newFilters) => {
            setFilters(newFilters);
            setGenerateReport(true);
          }}
          reportId={storedReport?.id}
        />
      )}
    </>
  );
}
